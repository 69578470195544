// eslint-disable-next-line no-redeclare,no-unused-vars
function buildField(cont, param, key, set = {}, next, index, line) {
  var field = this;
  var element;
  var row = {};
  var view = param.view;
  var object = param.object;
  var objectSub = param.objectSub;
  var refType;

  if (param.row) row = param.row;
  else if (param.id && object) row = getData(object, param.id);
  var settings = Object.assign({}, set);
  if (!settings || isEmptyObject(settings))
    settings = getFieldSettings(param, key);
  if (!line) line = {};

  // field type
  var type = 'input';
  if (settings.field) type = settings.field;
  if (next && settings['field' + next]) type = settings['field' + next];
  if (param.table && settings.fieldTable) type = settings.fieldTable;
  if (param.bulk) {
    if (inArray(type, ['mail'])) return;
    else if (inArray(settings.type, ['date', 'datetime'])) type = 'calendar';
    else if (inArray(type, ['read', 'table'])) type = 'input';
  }

  // value
  var value = null;
  if (isset(param.value)) value = param.value;
  else if (row[key] != null) value = Object.assign({}, row)[key];
  else if (settings.array) value = [];

  // special field option
  var fieldKey = 'Field';
  if (next) fieldKey += next;
  var length = fieldKey.length;
  $.each(settings, function (option, val) {
    if (option.slice(-length) == fieldKey) {
      settings[option.slice(0, option.length - length)] = val;
    }
  });

  // read
  var read = false;
  var noRemove = false;
  if (param.read) read = true;
  else if (
    param.readFields &&
    inArray(key, param.readFields) &&
    !inArray(type, ['read', 'table', 'file'])
  )
    read = true;
  else if (settings.readFilled && value != 0 && value != null) read = true;
  else if (settings.read) {
    if (isPlainObject(settings.read)) {
      if (checkWhere(row, settings.read, {object: object})) read = true;
    } else if (isArray(settings.read)) {
      if (checkWhere({}, {role: settings.read})) read = true;
    } else read = true;
    if (read) noRemove = true;
  }
  if (read && settings.editRead) read = false;
  else if (read && inArray(type, ['pdf'])) return;
  else if (
    read &&
    next &&
    !settings.json &&
    !inArray(type, ['read', 'table', 'file', 'button', 'mail'])
  )
    return;
  else if (read && inArray(type, ['upload', 'add', 'addNew', 'button'])) return;
  else if (read && type == 'button' && settings.func) return;
  if (settings.noRemove) noRemove = true;

  // type
  var typeOrig = type;
  if (type == 'editor' && !param.showEditor && !window.tinymce) type = 'read';
  else if (read && inArray(type, ['checkbox', 'radio', 'editor', 'select']))
    type = 'read';
  else if (type == 'read') {
    if (settings.type == 'double') type = 'decimal';
    else if (settings.type == 'int' && !settings.reference && !settings.value)
      type = 'number';
    read = true;
  } else if (type == 'time') type = 'calendar';
  if (
    settings.reference &&
    inArray(type, ['input', 'checkbox', 'radio', 'calendar'])
  ) {
    refType = type;
    type = 'read';
  }

  // ident
  var ident = [object, objectSub, row.id, key, type].filter(Boolean).join('_');
  if (param.fieldIdent) ident = param.fieldIdent;

  // callback value
  if (isFunction(value)) {
    if (!next) value(cont);
    value = null;
  }

  // check reference data
  if (
    settings.reference &&
    value &&
    !next &&
    !param.table &&
    !param.filter &&
    !settings.refTable &&
    !settings.refValue
  ) {
    if (isArray(value))
      $.each(value, function (i, val) {
        if (settings.amount && isString(val) && str_contains(val, 'x'))
          val = val.slice(strpos(val, 'x') + 1);
        if (val && !getObjectValue(data, [settings.reference, val]))
          ajax({
            object: settings.reference,
            objectSub: settings.objectSub,
            id: val,
          });
      });
    else if (!getObjectValue(data, [settings.reference, value]))
      ajax({
        object: settings.reference,
        objectSub: settings.objectSub,
        id: value,
      });
  }
  if (isset(settings['value' + fieldKey])) value = settings['value' + fieldKey];

  // multi data
  if (settings.multiple && !isString(value)) {
    if (!settings.prepend && !isset(index)) index = 0;
    if (Number.isInteger(index) && value && value.length && value[index]) {
      value = value[index];
      var j = 0;
      if (next) j = next - 1;
      if (settings.json && settings.fields) value = value[settings.fields[j]];
    } else if (settings.default) value = calculate(settings.default, settings);
    else if (value != null) value = '';
    if (type == 'decimal') {
      settings.number = true;
      settings.decimal = true;
    } else if (type == 'number') settings.number = true;
  }

  // fields
  field.addTable = function () {
    var pm = {
      object: settings.reference,
      objectSub: settings.objectSub,
      refObj: object,
      refObjSub: objectSub,
      refKey: key,
      refRow: row,
      view: view,
      type: 'table',
      read: read,
    };
    if (settings.refTable) {
      pm.where = convertWhere(
        optionValue({
          settings: settings,
          key: 'refWhere',
          objectSub: objectSub,
        }),
        {
          object: object,
          row: row,
          simple: true,
          emptyValues: true,
        }
      );
      pm.refTable = true;
      if (row.id == 'new') pm.noRegister = true;
    } else if (!settings.reference) {
      pm.directData = true;
      pm.noRegister = true;
      pm.save = function () {
        element.save();
      };
    } else {
      pm.idData = true;
      pm.noRegister = true;
    }

    // build table fields
    if (
      settings.tableFields &&
      settings.tableFieldsCond &&
      checkWhere(row, settings.tableFieldsCond, {nullAsEmpty: true})
    ) {
      pm.tableFields = settings.tableFields;
    } else if (
      settings.tableFields2 &&
      settings.tableFields2Cond &&
      checkWhere(row, settings.tableFields2Cond, {nullAsEmpty: true})
    ) {
      pm.tableFields = settings.tableFields2;
      pm.tableFieldsSource = 'tableFields2';
    } else if (
      settings.tableFields3 &&
      settings.tableFields3Cond &&
      checkWhere(row, settings.tableFields3Cond, {nullAsEmpty: true})
    ) {
      pm.tableFields = settings.tableFields3;
      pm.tableFieldsSource = 'tableFields3';
    } else if (settings.tableFields && !settings.tableFieldsCond) {
      pm.tableFields = settings.tableFields;
    }
    if (settings.json && settings.hideUndefined && row[key] && row[key][0])
      $.each(pm.tableFields, function (i, key2) {
        if (!isset(row[key][0][key2])) delete pm.tableFields[i];
      });

    if (!settings.footer) pm.noFooter = true;
    if (settings.fields) pm.fields = settings.fields;
    if (settings.edit) pm.edit = settings.edit;
    if (settings.editEmpty) pm.editEmpty = settings.editEmpty;
    if (settings.labels) pm.labels = settings.labels;
    if (settings.sort) pm.sort = settings.sort;
    if (settings.sortable) pm.sortable = settings.sortable;
    if (settings.jumpTo) pm.jumpTo = settings.jumpTo;
    if (settings.searchBar) pm.addSearch = true;
    if (settings.contextRow) pm.contextRow = settings.contextRow;
    if (settings.pivotTable) pm.pivotTable = settings.pivotTable;
    if (settings.noDisable) pm.noDisable = true;
    if (settings.noLink) pm.noLink = true;
    else if (settings.disabled && checkWhere(row, settings.disabled))
      pm.noLink = true;
    if (noRemove) {
      pm.noRemove = true;
      pm.noSelect = true;
    }
    if (settings.amount) pm.amountValue = true;
    element = new buildTable(cont, pm, [], row[key + '_sum']);
    pm.element = element;

    element.val = function (val, first) {
      if (defined(val)) {
        value = val;
        if (!val) val = [];
        else if (
          settings.tableFields &&
          arrayEqual(settings.tableFields, ['key', 'value'])
        ) {
          var valArray = [];
          $.each(val, function (k, v) {
            valArray.push({key: k, value: v});
          });
          val = valArray;
        } else if (!isArray(val)) val = [val];
        if (!settings.json || first === true) {
          var pm2 = {data: val};
          if (first !== true) pm2.emptyTable = true;
          element.table.addData(pm2);
        }
        changed();
      } else {
        if (settings.reference) value = pm.table.ids;
        else if (pm.directData && settings.json) {
          var tableData = [];
          $.each(element.table.tr, function (i, tr) {
            if (tr.is(':hidden')) return;
            var row = {};
            if (!settings.edit) row = value[i];
            $.each(tr.els, function (key, el) {
              row[key] = el.val();
            });
            tableData.push(row);
          });
          value = tableData;
        } else if (pm.directData) {
          value = [];
          $.each(element.table.tr, function (i, tr) {
            var row2 = {};
            $.each(tr.els, function (key, el) {
              row2[key] = el.val();
            });
            value.push(row2);
          });
        }
        return value;
      }
    };
    if (settings.refTable)
      cont.update = function () {
        element.table.where = convertWhere(
          optionValue({
            settings: settings,
            key: 'refWhere',
            objectSub: objectSub,
          }),
          {object: object, row: row, simple: true}
        );
        element.table.loadData();
      };
    cont.add = function (row, noSave) {
      element.table.add(row);
      if (!noSave)
        setTimeout(function () {
          element.save();
        }, 500);
    };
    cont.addMulti = function (ids) {
      if (!value) value = [];
      value = arrayMerge(value, ids);
      value = arrayUnique(value);
      element.val(value);
      element.save();
    };

    if (
      settings.refTable &&
      row.id != 'new' &&
      (!settings.refCond ||
        checkWhere(row, settings.refCond, {nullAsEmpty: true}))
    ) {
      var send = {table: true, where: pm.where, refObj: object, refKey: key};
      if (settings.sort) send.sort = settings.sort;
      ajax({
        object: settings.reference,
        objectSub: settings.objectSub,
        send: send,
        noUpdate: true,
        noAdd: true,
        callback: function (xhr) {
          element.table.addData({
            data: xhr.ids,
            amount: xhr.amount,
            sum: xhr.sum,
          });
          element.table.buildFooter();
        },
      });
    } else if (value && !settings.refTable) element.val(value, true);

    // footer updater
    if (settings.footer && element.tfoot) {
      register(element.tfoot, object, row.id, key + '_sum');
      element.tfoot.val = function (values) {
        $.each(values, function (key2, value2) {
          var settings2 = getFieldSettings({object: settings.reference}, key2);
          element.tfoot.find('th[key=' + key2 + ']').text(
            convert({
              value: value2,
              settings: settings2,
              key: key2,
              object: settings.reference,
            })
          );
        });
      };
    }
  };
  field.addFile = function () {
    var file = {};
    element = $('<div id="' + ident + '" class="field-file"></div>').appendTo(
      cont
    );
    element.val = function (val, refObj, valId) {
      if (refObj && refObj == settings.reference) {
        if (file) {
          if (file.type == 'video')
            element.children('video:last source').attr('src', val);
          else if (file.type == 'image' || file.type == 'img')
            element.children('img:last').attr('src', val);
          else if (file.type == 'pdf')
            element.children('object:last').attr('data', val);
          if (val) file.file = val;
          return element;
        } else val = valId;
      }
      if (defined(val)) {
        value = val;
        element.empty();
        if (!val) return;
        if (value) {
          var files = [];
          var imageIndex = [];
          var lightboxObj;
          if (isArray(value)) files = value;
          else files.push(value);

          $.each(files, function (i, val) {
            if (object == 'document' && !data[object][row.id].type)
              setTimeout(function () {
                element.val(val, refObj, valId);
              }, 100);
            else if (object == 'document') file = data[object][row.id];
            else if (isString(val) && str_contains(val, '.'))
              file = {file: val, type: val.slice(val.lastIndexOf('.') + 1)};
            else if (settings.reference)
              file = getObjectValue(data, [settings.reference, val]);
            if (!file || (file.disabled && object != 'document')) return;

            // var fileCont = $('<div class="preview-images"></div>').appendTo(element);
            var fileCont = $('<div></div>').appendTo(element);
            if (file.type == 'video')
              $(
                '<video class="w-100" preload="none" controls><source src="' +
                  file.file +
                  '" type="video/mp4" data-id="' +
                  file.id +
                  '"></video>'
              ).appendTo(fileCont);
            else if (
              inArray(file.type, ['image', 'img', 'jpg', 'png', 'svg'])
            ) {
              $(
                '<img src="' +
                  file.file +
                  '" data-id="' +
                  file.id +
                  '" class="img-fluid rounded-1 link mb-2" data-index="' +
                  imageIndex +
                  '">'
              )
                .appendTo(fileCont)
                .on(click, function () {
                  lightboxObj.open(parseInt($(this).attr('data-index')));
                });
              imageIndex++;
            } else if (inArray(file.type, ['pdf'])) {
              var el = $(
                '<object class="w-100" data="' +
                  file.file +
                  '" type="' +
                  file.mime +
                  '" data-id="' +
                  file.id +
                  '"></object>'
              ).appendTo(fileCont);
              el.height(($(window).height() / 4) * 3);
            }
          });

          if (imageIndex) lightboxObj = new mdb.Lightbox(element[0]);
          if (settings.multi && files.length && !read)
            element.sortable({
              cursor: 'move',
              placeholder: 'col-md bg-warning rounded-1',
              forcePlaceholderSize: true,
              update: function () {
                element.save();
              },
            });

          var menu = {show: info.context.openRow};
          if (!noRemove) {
            menu.remove = info.context.removeRow;
            menu.disable = info.context.disableRow;
          }
          if (user.admin && role == 'superadmin')
            menu.delete = info.context.deleteRow;
          contextMenu(element.find('[data-id]'), menu, function (type, target) {
            var id = target.attr('data-id');
            if (type == 'remove' || type == 'disable' || type == 'delete') {
              if (type == 'disable')
                ajax({
                  object: settings.reference,
                  id: id,
                  post: true,
                  send: {disabled: 1},
                });
              else if (type == 'delete')
                ajax({
                  object: settings.reference,
                  id: id,
                  delete: true,
                });
              target.remove();
              value = element.val();
              element.save();
            } else if (type == 'show')
              ajax({
                func: 'detail',
                object: settings.reference,
                objectSub: settings.objectSub,
                id: target.attr('data-id'),
                modal: true,
              });
          });
        }
        changed();
        return element;
      } else {
        if (settings.multi) {
          value = [];
          element.find('[data-id]').each(function () {
            value.push(parseInt($(this).attr('data-id')));
          });
        } else if (element.find('[data-id]')[0])
          value = parseInt(element.find('[data-id]').attr('data-id'));
        else value = 0;
        return value;
      }
    };
    if (value) element.val(value);
    else if (settings.placeholder) element.val(settings.placeholder);
    if (settings.reference) {
      cont.add = function (row, noSave) {
        if (settings.multi && isArray(value)) value.push(row.id);
        else value = row.id;
        element.val(value);
        if (!noSave) element.save();
      };
      register(element, settings.reference, value, 'file');
      // register(element, settings.reference, value, 'disabled');
    }
  };
  field.addButton = function () {
    var content = [];
    if (settings.icon || settings.iconBtn) {
      var icon = settings.icon;
      if (settings.iconBtn) icon = settings.iconBtn;
      if (strpos(icon, 'fa-') === false) icon = 'fa-regular fa-' + icon;
      if (settings.iconStack) {
        content.push('<i class="' + icon + ' fa-stack-1x ms-2"></i>');
        content.push(
          '<i class="icon fa-regular fa-' +
            settings.iconStack +
            ' fa-stack-1x me-0"></i>'
        );
      } else content.push('<i class="' + icon + '"></i>');
    }
    if (settings.labelBtn && (!param.table || !content.length))
      content.push('<span>' + settings.labelBtn + '</span>');

    var func = function (evt, skipConfirm) {
      evt.stopPropagation();

      if (
        settings.confirm &&
        !skipConfirm &&
        (!settings.confirmCond || checkWhere(row, settings.confirmCond))
      ) {
        if (isPlainObject(settings.confirm))
          buildPromptPopup(settings.confirm, function (result) {
            if (result) {
              evt.values = result;
              func(evt, true);
            }
          });
        else
          buildConfirmPopup(settings.confirm, function (result) {
            if (result) func(evt, true);
          });
        return;
      }

      // open link
      if (settings.link) {
        var link = row[key];
        if (settings.link != '1') {
          var pm = {
            text: settings.link,
            row: row,
            object: object,
            index: index,
            emptyOnMissingVar: true,
          };
          if (
            strpos(settings.link, 'http') === 0 &&
            strpos(settings.link, '__') !== 0
          )
            pm.encode = true;
          if (isEmptyObject(row) && param.value) row.value = param.value;
          link = convertPattern(pm);
          if (!link) {
            requestIdleCallback(function () {
              element.trigger(click);
            });
            return;
          }
        } else if (isArray(link)) link = link[index];
        if (strpos(link, ':') === false) link = 'http://' + link;
        if (settings.iframe)
          buildIframePopup(settings.label + ' ' + row.short, link);
        else if (strpos(link, 'http') === 0) window.open(link, '_blank');
        else {
          noLogoutInfo = true;
          document.location = link;
        }
      }

      // show info
      else if (settings.info) {
        buildPopup({
          content: convertPattern({
            text: nl2br(settings.info),
            row: row,
            object: object,
          }),
        });
      }

      // open table or calendar
      else if (settings.func == 'calendar' || settings.func == 'table') {
        pm = {
          object: settings.reference,
          objectSub: settings.objectSub,
          send: {},
          type: settings.func,
        };
        if (!settings.fullTable) pm.modal = true;
        if (settings.where) {
          var where = convertWhere(settings.where, {
            object: object,
            row: row,
          });
          if (where.start) pm.send.start = where.start;
          else pm.send.where = where;
          pm.adopt = where;
        }
        if (settings.func == 'table')
          pm.addNewCallback = function () {
            line.element.getList();
          };
        ajax(pm);
      } else if (
        settings.func == 'open' &&
        settings.reference &&
        settings.where
      ) {
        where = Object.assign({}, settings.where);
        where = convertWhere(where, {object: object, row: row});
        pm = {
          object: settings.reference,
          objectSub: settings.objectSub,
          send: {where: where},
          callback: function (xhr) {
            pm.modal = true;
            if (xhr.amount) pm.id = xhr.ids[0];
            else pm.adopt = where;
            if (settings.savePrompt) pm.savePrompt = true;
            detail(pm);
          },
        };
        ajax(pm);
      } else if (settings.func == 'adopt' && settings.adopt) {
        $.each(settings.adopt, function (key, value) {
          setData(object, row.id, key, value);
        });
        element.save(false, settings.adopt);
      } else if (
        settings.func == 'pick' &&
        settings.funcValue &&
        isPlainObject(settings.funcValue)
      ) {
        $.each(settings.funcValue, function (key, value) {
          view.element.view.lines[key].element.val(row[value]).save();
        });
      } else if (
        settings.func == 'value' &&
        settings.funcValue &&
        isPlainObject(settings.funcValue)
      ) {
        pm = {
          object: object,
          objectSub: param.objectSub,
          id: row.id,
          post: true,
          send: settings.funcValue,
        };
        ajax(pm);
      } else if (settings.func == 'value' && settings.funcValue) {
        var value = calculate(settings.funcValue, settings, row, {
          object: object,
          element: element,
        });
        if (value === false) return;
        element.val(value);
        element.save();
      }

      // show as view
      else if (settings.func == 'showAsView') {
        ajax({
          func: 'detail',
          object: settings.reference,
          objectSub: settings.objectSub,
          id: row[key],
          thinView: true,
        });
      }

      // scan code
      else if (settings.func == 'scan') {
        buildScan(function (code) {
          if (settings.reference) {
            var adopt = {};
            adopt[settings.funcValue] = code;
            element.addNew(adopt);
          } else line.element.val(code).save();
        });
      } else if (settings.func == 'show' && settings.funcValue)
        view.body
          .find('[key=' + settings.funcValue + ']')
          .toggleClass('d-none');
      else if (settings.func && isFunction(settings.func))
        settings.func(element, param);
      else if (settings.jsFunc && window[settings.jsFunc]) {
        window[settings.jsFunc](element, param);
      }

      // backend function
      else if (settings.func) {
        element.addClass('disabled');
        pm = {
          object: object,
          objectSub: param.objectSub,
          id: row.id,
          noTimeout: true,
          view: view,
          post: true,
          send: {func: settings.func, key: key},
          callback: function () {
            element.removeClass('disabled');
          },
        };
        if (settings.waiting) pm.waiting = true;
        if (settings.funcValue) pm.send.funcValue = settings.funcValue;
        if (settings.funcTableElements) {
          pm.send.ids = line.element.table.selected();
          if (!pm.send.ids.length) {
            showInfo(info.pickedEmpty, 'warning');
            element.removeClass('disabled');
            return;
          }
        }
        if (row.bulk) pm.send.ids = param.ids;
        if (evt.values) pm.send = Object.assign(pm.send, evt.values);
        if (row.id == 'new')
          element.save(function () {
            pm.id = row.id;
            ajax(pm);
          });
        else ajax(pm);
      }
    };

    element = $(
      '<button id="' +
        ident +
        '" class="btn btn-light" type="button" data-groupable="button">' +
        content.join(' ') +
        '</button>'
    )
      .appendTo(cont)
      .on(click, func);

    if (settings.tipBtn) element.addTooltip(settings.tipBtn);
    if (settings.active) element.addClass('active');
    if (settings.created && window[settings.created])
      window[settings.created](element);
  };
  field.addMail = function () {
    var icon = 'envelope';
    if (settings.iconBtn) icon = settings.iconBtn;
    element = $(
      '<button id="' +
        ident +
        '" class="btn btn-light mailer" type="button" data-groupable="button"><i class="icon fa-regular fa-' +
        icon +
        ' fa-fw"></i></button>'
    )
      .appendTo(cont)
      .on(click, function (evt) {
        evt.stopPropagation();
        if (settings['funcField' + next])
          ajax({
            object: object,
            objectSub: param.objectSub,
            id: row.id,
            view: view,
            post: true,
            send: {func: settings['funcField' + next], key: key},
            callback: function () {
              mailer({
                settings: settings,
                object: object,
                row: row,
                key: key,
                index: index,
              });
            },
          });
        else {
          var pm = {
            settings: settings,
            object: object,
            objectSub: param.objectSub,
            row: row,
            key: key,
            index: index,
          };
          if (checkSettings('table', settings))
            pm.attach = line.element.table.selected();
          mailer(pm);
        }
      });
    if (settings.labelBtn) element.append(' ' + settings.labelBtn);
    var tip = info.title.writeMail;
    if (settings.tipBtn) tip = settings.tipBtn;
    else if (settings.reference == 'customer')
      tip = info.tooltip.sendMailCustomer.replace('__name__', settings.label);
    else if (settings.reference == 'document')
      tip = info.tooltip.sendMailDocument.replace('__name__', settings.label);
    element.addTooltip(tip);

    contextMenu(
      element,
      {fastSave: settings.label + ' direkt verschicken'},
      function (type) {
        if (type == 'fastSave')
          ajax({
            object: object,
            id: row.id,
            send: {func: 'mail', pattern: settings.mailPattern, key: key},
          });
      }
    );
  };
  field.addDownload = function () {
    element = $(
      '<button id="' +
        ident +
        '" class="btn btn-light" type="button" data-groupable="button"><i class="icon fa-regular fa-download fa-fw"></i></button>'
    )
      .addTooltip(info.tooltip.downloadDocument)
      .appendTo(cont)
      .on(click, function () {
        download(row);
      });
  };
  field.addRead = function () {
    if (typeOrig == 'editor')
      element = $(
        '<div class="wysiwyg field-read p-3 rounded-3 border border-1 pointer"></div>'
      ).appendTo(cont);
    else if (settings.multi)
      element = $('<ul id="' + ident + '"></ul>').appendTo(cont);
    else if (
      (settings.reference || settings.link || settings.table) &&
      !settings.noLink &&
      !param.noLink &&
      !refType
    ) {
      element = $(
        '<button id="' +
          ident +
          '" class="btn btn-link" type="button" data-groupable="text"></button>'
      ).appendTo(cont);
      if (getObjectValue(fields, [settings.reference, 'avatar']))
        element.addClass('py-0');
      else {
        element.content = $('<span></span>').appendTo(element);
        var icon;
        if (settings.reference)
          icon = getParamSettings(
            {object: settings.reference, objectSub: settings.objectSub},
            'icon'
          );
        else if (settings.table) icon = 'table';
        else if (settings.link) icon = 'link';
        if (icon)
          element.prepend(
            '<i class="icon fa-regular fa-' + icon + ' fa-fw me-2"></i>'
          );
      }
    } else if (settings.text)
      element = $('<div class="field-read me-2 lh-lg"></div>').appendTo(cont);
    else
      element = $(
        '<span id="' +
          ident +
          '" class="field-read border-0" data-groupable="text"></span>'
      ).appendTo(cont);

    // build tinymce editor (first plugin loading)
    if (typeOrig == 'editor')
      element.on(click, function () {
        if (!window.tinymce) {
          loadTinymce(function () {
            element.trigger(click);
          });
          return;
        }
        var elementNew = buildField(
          cont,
          Object.assign({}, param, {
            showEditor: true,
            focus: true,
            value: value,
          }),
          key,
          set,
          next,
          index,
          line
        );
        element.replaceWith(elementNew);
        line.element = elementNew;
        return;
      });
    else if (refType) {
      var pmRef = {
        object: settings.reference,
        id: value,
        view: view,
        injection: true,
        // classField: 'd-inline',
        refField: element,
        ref: row,
        refObj: object,
        refObjSub: objectSub,
        adopt: settings.adopt,
      };
      if (!settings.multi) {
        var refField = buildField(element, pmRef, settings.refField);
        var context = {};
        context.show = info.context.openRow;
        context.copyId = 'Datensatz in die Zwischenablage legen';
        contextMenu(refField, context, function (type) {
          if (type == 'show')
            ajax({
              func: 'detail',
              object: settings.reference,
              objectSub: settings.objectSub,
              id: pmRef.id,
              modal: true,
            });
          else if (type == 'copyId')
            navigator.clipboard.writeText(settings.reference + ',' + value);
        });
      }
    } else if (settings.reference && settings.refTable && settings.refWhere)
      element.on(click, function () {
        if (row.id == 'new') return;
        var where = $.extend(true, {}, settings.refWhere);
        where = convertWhere(where, {object: object, row: row, simple: true});
        ajax({
          func: 'overview',
          object: settings.reference,
          objectSub: settings.objectSub,
          modal: true,
          noButtons: true,
          send: {
            table: true,
            where: where,
            refObj: object,
            refKey: key,
            sort: settings.sort,
          },
          ident: settings.reference + rand(),
          tableFields: settings.tableFields,
          sort: settings.sort,
          noUpdate: true,
          noAdd: true,
          refLine: cont,
          refObj: object,
          refObjSub: objectSub,
          refId: row.id,
          refKey: key,
        });
      });
    else if (
      settings.reference &&
      !settings.noLink &&
      !param.noLink &&
      !settings.multi
    ) {
      element.on(click, function () {
        if (settings.reference == 'document' && settings.func != 'detail') {
          var pm = {id: value, buttons: {}};
          if (checkSettings('mail', settings)) {
            pm.buttons[settings.label + ' verschicken'] = function () {
              mailer({
                settings: settings,
                object: object,
                row: row,
                key: key,
                close: 'prev',
              });
            };
          }
          // if (settings.openRowRight && getScreenWidth() > 1920)
          buildFilePopup(pm);
        } else {
          pm = {
            func: 'detail',
            object: settings.reference,
            objectSub: settings.objectSub,
            id: value,
            adopt: settings.adopt,
            ref: row,
            modal: true,
          };
          ajax(pm);
        }
        view.lastClick = key;
      });
      context = {};
      context.showAsView = info.context.openRowRight;
      context.showInTab = info.context.openRowTab;
      context.copyId = info.context.copyRow;
      contextMenu(element, context, function (type) {
        if (type == 'showAsView')
          ajax({
            func: 'detail',
            object: settings.reference,
            objectSub: settings.objectSub,
            id: value,
            adopt: settings.adopt,
            ref: row,
            thinView: true,
          });
        else if (type == 'showInTab')
          window.open(
            document.location.origin +
              '/' +
              settings.reference +
              (settings.objectSub ? '+' + settings.objectSub : '') +
              ',' +
              value
          );
        else if (type == 'copyId')
          navigator.clipboard.writeText(settings.reference + ',' + value);
      });
    } else if (settings.link) {
      element.on(click, function () {
        var link = element.text();
        if (settings.link != '1')
          link = convertPattern({
            text: settings.link,
            row: row,
            object: object,
            index: index,
          });
        window.open(link, '_blank');
      });
    } else if (settings.table) {
      element.on(click, function () {
        var view = buildPopup({title: settings.label, bodyCard: true});
        var set = $.extend(true, settings, {field: 'table'});
        buildField(view.body, param, key, set);
      });
      value = value.length;
    } else {
      if (settings.field == 'editor' || settings.field == 'textarea')
        element.addClass('big');
      if (settings.number && settings.entity)
        element.addClass('input-group-text').removeClass('border-0');
      contextMenu(element, {copy: info.context.copyValue}, function (type) {
        if (type == 'copy') {
          var selection = window.getSelection();
          var range = document.createRange();
          range.selectNodeContents(element[0]);
          selection.removeAllRanges();
          selection.addRange(range);
          document.execCommand('copy');
          selection.removeAllRanges();
        }
      });
    }
    element.val = function (val, objectCheck, idCheck) {
      if (defined(val)) {
        var old = value;
        if (
          !object ||
          !objectCheck ||
          (object == objectCheck && row.id == idCheck)
        )
          value = val;
        if (
          settings.reference &&
          settings.refTable &&
          settings.refUpdate &&
          row.id != 'new' &&
          !objectCheck &&
          !param.bulk
        )
          cont.saveAmount();
        if (settings.refField && settings.value) {
          val = row[settings.value];
        }

        if (typeOrig == 'editor') {
          element.html(value);
        } else if (refType && !settings.multi) {
          if (old && val != old) {
            refField.remove();
            pmRef.id = val;
            refField = buildField(element, pmRef, settings.refField);
            contextMenu(
              refField,
              {show: info.context.openRow},
              function (type) {
                if (type == 'show')
                  ajax({
                    func: 'detail',
                    object: settings.reference,
                    objectSub: settings.objectSub,
                    id: pmRef.id,
                    modal: true,
                  });
              }
            );
          }
        } else if (settings.reference && settings.multi && !settings.refTable) {
          if (value && !isArray(value)) value = [value];
          element.empty();
          if (value)
            $.each(value, function (i, id) {
              if (!id) return;
              var text = convert({value: id, settings: settings, key: key});
              if (!text) return;

              var li = $('<li data-id="' + id + '"></li>').appendTo(element);
              if (refType) {
                pmRef.id = id;
                buildField(li, pmRef, settings.refField);
              }
              var el = $('<span>' + text + '</span>').appendTo(li);
              if (!settings.noLink && !param.noLink)
                el.addClass('link').on(click, function () {
                  if (settings.reference == 'document')
                    buildFilePopup({id: id, sign: settings.sign});
                  else
                    ajax({
                      func: 'detail',
                      object: settings.reference,
                      objectSub: settings.objectSub,
                      id: id,
                      modal: true,
                    });
                  if (param.table)
                    setTimeout(function () {
                      tableScroll(element);
                    }, 250);
                });

              var short = getParamSettings(settings, 'short', null, true, true);
              register(el, settings.reference, id, short);
              el.val = function () {
                el.html(convert({value: id, settings: settings, key: key}));
              };

              var context = {};
              if (settings.reference) {
                if (settings.reference == 'document')
                  context.download = 'Dokument herunterladen';
                context.show = info.context.openRow;
                context.showAsView = 'Datensatz rechts als Fenster öffnen';
                context.showInTab = 'Datensatz im extra Tab öffnen';
                context.copyId = 'Datensatz in die Zwischenablage legen';
                var refRow = getData(settings.reference, id);
                if (refRow.type == 'pdf') el.addPdfTooltip(refRow.file);
                // el.addTooltip(convert({value: getObjectValue(data, [settings.reference, id, 'createStamp']), settings: fields[settings.reference].createStamp, key: key}));
              }
              if (param.table && checkSettings('addNew', settings))
                context.addNew = settings.label + ' erstellen';
              if (
                !noRemove ||
                (isPlainObject(noRemove) && !checkWhere(row, noRemove))
              )
                context.remove = info.context.removeRow;
              if (settings.addDisable) {
                context.disable = info.context.disableRow;
                if (user.admin && role == 'superadmin')
                  context.delete = info.context.deleteRow;
              }
              contextMenu(el, context, function (type) {
                if (type == 'show')
                  ajax({
                    func: 'detail',
                    object: settings.reference,
                    objectSub: settings.objectSub,
                    id: id,
                    modal: true,
                  });
                else if (type == 'showAsView')
                  ajax({
                    func: 'detail',
                    object: settings.reference,
                    objectSub: settings.objectSub,
                    id: id,
                    thinView: true,
                  });
                else if (type == 'showInTab')
                  window.open(
                    document.location.origin +
                      '/' +
                      settings.reference +
                      (settings.objectSub ? '+' + settings.objectSub : '') +
                      ',' +
                      id
                  );
                else if (type == 'download')
                  download(data[settings.reference][id]);
                else if (type == 'addNew') element.addNew();
                else if (type == 'copyId')
                  navigator.clipboard.writeText(settings.reference + ',' + id);
                else if (
                  type == 'remove' ||
                  type == 'disable' ||
                  type == 'delete'
                ) {
                  //if (!confirm('Wollen sie "'+el.text()+'" wirklich '+label+'?')) return;
                  if (type == 'disable')
                    ajax({
                      object: settings.reference,
                      id: id,
                      post: true,
                      send: {disabled: 1},
                    });
                  else if (type == 'delete')
                    ajax({object: settings.reference, id: id, delete: true});
                  value.splice(i, 1);
                  li.remove();
                  element.save();
                }
              });
            });

          // sort entries
          element.sortable({
            cursor: 'move',
            containment: cont,
            update: function () {
              value = cont
                .find('li[data-id]')
                .map(function () {
                  return $(this).attr('data-id');
                })
                .get();
              element.save();
            },
          });

          if (param.table && checkSettings('addNew', settings))
            contextMenu(
              element.parent(),
              {addNew: settings.label + ' erstellen'},
              function (type) {
                if (type == 'addNew') element.addNew();
              }
            );
        } else if (isArray(value) && !settings.table) {
          $.each(value, function (i, val) {
            if (val) $('<li>' + val + '</li>').appendTo(element);
          });
        } else if (!isArray(value)) {
          var valueRead = '';
          if (settings.reference && value) {
            var refRow = getData(settings.reference, value);
            if (refRow.disabled && settings.reference != 'user') return;
            var menu = {};
            if (settings.reference == 'document') {
              element.off('mouseover');
              menu.show = info.context.openRow;
              menu.download = 'Dokument herunterladen';
              if (refRow.type == 'pdf') element.addPdfTooltip(refRow.file);
              // else if (refRow.createStamp) element.addTooltip(convert({value: refRow.createStamp, settings: fields[settings.reference].createStamp, key: key}));
            }
            if (!noRemove) {
              menu.remove = info.context.removeRow;
              if (settings.addDisable) {
                menu.disable = info.context.disableRow;
                if (user.admin && role == 'superadmin')
                  menu.delete = info.context.deleteRow;
              }
            }
            contextMenu(element, menu, function (type) {
              if (type == 'show')
                ajax({
                  func: 'detail',
                  object: settings.reference,
                  objectSub: settings.objectSub,
                  id: value,
                  modal: true,
                });
              else if (type == 'download')
                download(data[settings.reference][value]);
              else if (
                type == 'remove' ||
                type == 'disable' ||
                type == 'delete'
              ) {
                if (type == 'disable')
                  ajax({
                    object: settings.reference,
                    id: value,
                    post: true,
                    send: {disabled: 1},
                  });
                else if (type == 'delete')
                  ajax({object: settings.reference, id: value, delete: true});
                element.val(0).save();
              }
            });
            if (!settings.refTable) {
              var short = getParamSettings(settings, 'short', null, true, true);
              register(element, settings.reference, value, short);
              register(element, settings.reference, value, 'disabled');
            }
            var pm = {
              value: value,
              settings: settings,
              row: row,
              object: object,
              key: key,
              element: element,
            };
            valueRead = convert(pm);
            if (pm.disabled) $(this).addClass('text-decoration-line-through');
            // 						if (!getObjectValue(data,[settings.reference,value])) ajax({object:settings.reference,objectSub:settings.objectSub,id:value});
          }
          // else if (settings.value && value && !param.id) value = convertPattern({text:settings.value,row:row,object:object}); -> not working for typeless fields ex. user->carddav
          else if (
            settings.value &&
            (value || !settings.type) &&
            !settings.table &&
            object
          ) {
            var pattern = settings.value;
            if (settings.values && isArray(pattern)) {
              pattern = pattern[settings.values.indexOf(value)];
              if (!pattern) pattern = end(settings.value);
            }
            if (str_contains(pattern, '__') || str_contains(pattern, ' ')) {
              valueRead = convertPattern({
                text: pattern,
                row: row,
                object: object,
                element: element,
                key: key,
                value: value,
                encode: settings.link,
              });
              if (!valueRead) valueRead = value;
            } else {
              valueRead = calculate(pattern, settings, row, {
                object: object,
                element: element,
                read: true,
              });
              if (str_contains(pattern, '.')) {
                var key2, refKey2;
                [key2, refKey2] = pattern.split('.');
                if (fields[object][key2])
                  register(
                    element,
                    fields[object][key2].reference,
                    row[key2],
                    refKey2
                  );
              }
            }
          } else if (isset(value))
            valueRead = convert({
              value: value,
              settings: settings,
              row: row,
              object: object,
              key: key,
            });
          else if (settings.refTable) valueRead = 0;

          if (value) element.removeClass('d-none');
          else element.addClass('d-none');

          if (
            valueRead &&
            settings.values &&
            settings.labels &&
            inArray(valueRead, settings.values)
          )
            valueRead = settings.labels[settings.values.indexOf(valueRead)];

          if (isset(row[key + '_read']) || settings.hash)
            setTimeout(function () {
              element.html(row[key + '_read']);
            }, 1000);
          else if (element.content) element.content.html(valueRead);
          else $(this).html(valueRead);

          if (!settings.refTable) {
            if (!valueRead) element.addClass('d-none');
            else element.removeClass('d-none');
          }
          if (param.table && checkSettings('addNew', settings))
            contextMenu(
              element.parent(),
              {
                addNew: info.context.createRow.replace(
                  '__name__',
                  settings.label
                ),
              },
              function (type) {
                if (type == 'addNew') element.addNew();
              }
            );
          if (param.table && checkSettings('add', settings))
            contextMenu(
              element.parent(),
              {
                add: info.context.chooseRow.replace('__name__', settings.label),
              },
              function (type) {
                if (type == 'add') element.add();
              }
            );
        }
        if (settings.refTable || settings.table)
          element.addTooltip(
            info.tooltip.showRow.replace('__name__', settings.label)
          );

        changed();
        return element;
      } else {
        if (isset(row[key + '_read'])) return row[key + '_read'];
        else return value;
      }
    };
    if (settings.reference && settings.refTable && settings.refWhere)
      cont.saveAmount = function (add) {
        if (add) value += add;
        if (value && element.content) element.content.text(value);
        ajax({
          object: object,
          objectSub: objectSub,
          id: row.id,
          post: true,
          send: {func: 'saveAmount', key: key, add: add},
        });
      };
    else if (settings.reference && settings.multi) {
      cont.add = function (row, noSave, noUpdate) {
        if (!isPlainObject(row)) row = data[settings.reference][row];
        if (!value) value = [];
        if (!inArray(row.id, value)) value.push(row.id);
        if (!noUpdate) element.val(value);
        if (!noSave) element.save();
      };
      cont.addMulti = function (ids) {
        if (!value) value = [];
        value = arrayMerge(value, ids);
        value = arrayUnique(value);
        element.val(value);
        element.save();
      };
      cont.removeMulti = function (ids) {
        if (!value || !ids) return;
        var valueNew = [];
        $.each(value, function (i, id) {
          if (!inArray(id, ids)) valueNew.push(id);
        });
        value = valueNew;
        element.val(value);
        element.save();
      };
    } else if (settings.reference)
      cont.add = function (row2, noSave) {
        element.val(row2.id);
        if (!noSave) element.save(null, {refObj: object, refId: row2.id});
      };
    if (param.table) {
      element.add = function () {
        var pm = {
          func: 'overview',
          top: view,
          object: settings.reference,
          objectSub: settings.objectSub,
          ident: settings.reference + rand(),
          modal: true,
          noButtons: true,
        };
        if (settings.where)
          pm.send = {
            where: convertWhere(settings.where, {
              object: object,
              row: row,
            }),
          };
        if (settings.objectSubAdd) pm.objectSub = settings.objectSubAdd;
        pm.picker = function (ids, popup) {
          value = ids;
          element.save();
          popup.close();
        };
        ajax(pm);
        setTimeout(function () {
          tableScroll(element);
        }, 250);
      };
    }
    element.update = function () {
      $(this).html(convert({value: value, settings: settings, key: key}));
    };
    if (settings.width) element.width(settings.width);
    element.val(value);

    if (settings.limitHeight) {
      setTimeout(() => {
        if (element[0].offsetHeight >= 250) {
          $('<button class="btn btn-light">Zeige mehr...</button>')
            .appendTo(cont)
            .on('click', function () {
              buildPopup({
                title: settings.label,
                size: 'sm',
                bodyCard: true,
                content: value,
              });
            });
          element[0].classList.add('read-more');
        }
      }, 50);
    }
  };
  field.addAdd = function () {
    var label = '';
    if (settings.labelAdd) label = settings.labelAdd;
    else if (value && !settings.multi && !settings.refTable)
      label = info.tooltip.changeRow.replace('__name__', settings.label);
    else label = info.tooltip.addRow.replace('__name__', settings.label);
    var refObj = settings.reference;
    if (settings.referenceAdd) refObj = settings.referenceAdd;
    var refObjSub = settings.objectSub;
    if (settings.objectSubAdd) refObjSub = settings.objectSubAdd;
    var adopt = {};

    if (
      settings.adopt &&
      (!settings.adoptCond || checkWhere(row, settings.adoptCond))
    )
      adopt = Object.assign(adopt, settings.adopt);
    if (settings.adopt2 && checkWhere(row, settings.adopt2Cond))
      adopt = Object.assign(adopt, settings.adopt2);
    if (settings.adopt3 && checkWhere(row, settings.adopt3Cond))
      adopt = Object.assign(adopt, settings.adopt3);

    element = $(
      '<button id="' +
        ident +
        '" type="button" class="btn btn-light" data-groupable="button" data-type="' +
        type +
        '"><i class="icon fa-regular fa-sign-in fa-fw"></i></button>'
    )
      .appendTo(cont)
      .addTooltip(label)
      .on(click, function () {
        element.addClass('active');
        var pm = {
          func: 'overview',
          modal: true,
          object: refObj,
          objectSub: refObjSub,
          title: label,
          btn: element,
          send: {noSums: true},
          ident: ident + '_table',
          noButtons: true,
          refObj: object,
          refId: row.id,
        };
        if (settings.tableFieldsPicker)
          pm.tableFields = pm.send.tableFields = settings.tableFieldsPicker;
        var where = {};
        if (settings.whereFilter)
          where = $.extend(true, {}, settings.whereFilter);
        else if (settings.where) where = $.extend(true, {}, settings.where);
        // if (settings.refTable && settings.refWhere) where['$not'] = settings.refWhere;
        if (!settings.refTable) {
          if (
            isArray(row[key]) &&
            row[key].length &&
            row[key].length < 50 &&
            !settings.amount
          )
            where['$not'] = {id: row[key]};
          else if (!isArray(row[key]) && row[key])
            where['$not'] = {id: row[key]};
        }
        if (!isEmptyObject(where)) {
          where = convertWhere(where, {
            object: object,
            row: row,
            simple: true,
            noRef: true,
          });
          pm.send = {
            where: where,
            refId: row.id,
            refKey: key,
            refObj: object,
          };
        }

        if (settings.sort) pm.send.sort = settings.sort;
        if (
          !settings.array &&
          !settings.refTable &&
          !settings.multi &&
          !settings.multiple
        )
          pm.noSelect = true;
        pm.picker = function (ids, popup) {
          if (!isArray(ids)) ids = [ids];
          var copyIds = [];
          $.each(ids, function (i, id) {
            var noSave = false;
            if (i != ids.length - 1) noSave = true;
            var rowAdd = $.extend(true, {}, getData(refObj, id));

            // dataset from other db -> first save
            if (rowAdd.database) {
              ajax({
                post: true,
                object: refObj,
                objectSub: refObjSub,
                id: rowAdd.id,
                send: {editStamp: calculate('now')},
                noOpen: true,
                callback: function (xhr) {
                  if (xhr.open) pm.picker(xhr.open, popup);
                },
              });
              return;
            }

            if (settings.pickerMin && rowAdd[settings.pickerMin] <= 0) {
              showInfo(
                info.error.minimum.replace(
                  '__name__',
                  fields[refObj][settings.pickerMin].label
                ),
                'error'
              );
              return;
            }

            // copy row
            if (settings.copy) {
              if (settings.copyRefField) rowAdd = {};
              $.each(
                Object.assign({}, adopt, settings.copyAdopt),
                function (key, val) {
                  if (fields[refObj][val]) rowAdd[key] = row[val];
                  else rowAdd[key] = calculate(val, fields[refObj][key], row);
                }
              );
              var pm2 = {
                object: refObj,
                objectSub: settings.objectSub,
                objectSubAdd: settings.objectSubAdd,
                nameOrig: true,
                rowAdd: rowAdd,
                refField: settings.copyRefField,
                refObj: object,
              };
              if (!settings.refTable) {
                pm2.callback = function (xhr) {
                  copyIds.push(xhr.id);
                };
                var interval = setInterval(function () {
                  if (copyIds.length < ids.length) return;
                  cont.addMulti(copyIds);
                  clearInterval(interval);
                }, 100);
              }
              copy(id, pm2);
            }

            // add new
            else if (settings.addNew) {
              pm2 = {
                object: refObj,
                objectSub: refObjSub,
                id: 'new',
                post: true,
                send: {},
              };
              $.each(settings.addNew, function (key, val) {
                pm2.send[key] = rowAdd[val];
              });
              $.each(adopt, function (key, val) {
                pm2.send[key] = row[val];
              });
              pm2.callback = function (xhr) {
                cont.add(data[refObj][xhr.id], noSave);
              };
              ajax(pm2);
              return;
            }

            // save ref table
            else if (settings.refTable) {
              pm2 = {
                object: refObj,
                objectSub: refObjSub,
                id: id,
                post: true,
                send: {},
                callback: function () {
                  if (cont.saveAmount) cont.saveAmount(1);
                  if (cont.element.table.nonRegistered) {
                    registerTable(cont.element.table, object, param);
                    delete cont.element.table.nonRegistered;
                  }
                },
              };
              $.each(adopt, function (key, val) {
                pm2.send[key] = row[val];
              });
              ajax(pm2);
            }

            // add to table
            else if (cont.add) {
              cont.add(rowAdd, noSave);
              if (!noSave)
                setTimeout(function () {
                  if (cont.focusNext) cont.focusNext();
                }, 100);
            }
          });
          popup.close();
          element.removeClass('active');
        };
        pm.afterClose = function () {
          element.removeClass('active');
        };
        if (settings.where) pm.adopt = settings.where;
        ajax(pm);
      });

    contextMenu(element, {pasteId: info.context.pasteRow}, function (type) {
      if (type == 'pasteId')
        navigator.clipboard.readText().then(function (val) {
          const [pasteObject, pasteId] = val.split(',');
          if (
            pasteObject &&
            pasteObject == settings.reference &&
            pasteId &&
            isNumeric(pasteId)
          )
            cont.add(data[pasteObject][pasteId]);
        });
    });
  };
  field.addAddNew = function () {
    var label = settings.label + ' erstellen';
    if (settings.labelAddNew) label = settings.labelAddNew;
    var refObj = settings.reference;
    if (settings.referenceAddNew) refObj = settings.referenceAddNew;
    var refObjSub = settings.objectSub;
    if (settings.objectSubAddNew) refObjSub = settings.objectSubAddNew;

    element = $(
      '<button id="' +
        ident +
        '" class="btn btn-light" type="button" data-groupable="button" data-type="' +
        type +
        '"><i class="icon fa-regular fa-plus fa-fw"></i></button>'
    )
      .appendTo(cont)
      .addTooltip(label)
      .on(click, function () {
        var adopt = {};
        if (
          settings.adopt &&
          (!settings.adoptCond ||
            checkWhere(row, settings.adoptCond, {nullAsEmpty: true}))
        )
          adopt = Object.assign(adopt, settings.adopt);
        if (
          settings.adopt2 &&
          checkWhere(row, settings.adopt2Cond, {nullAsEmpty: true})
        )
          adopt = Object.assign(adopt, settings.adopt2);
        if (
          settings.adopt3 &&
          checkWhere(row, settings.adopt3Cond, {nullAsEmpty: true})
        )
          adopt = Object.assign(adopt, settings.adopt3);
        if (settings.adoptAddNew)
          adopt = Object.assign(adopt, settings.adoptAddNew);

        if (
          settings.edit &&
          isPlainObject(settings.edit) &&
          checkSettings('table', settings)
        ) {
          element.addTooltip(
            info.tooltip.addRow.replace('__name__', settings.label)
          );
          var add = {};
          if (refObj)
            add = dataNew({
              object: refObj,
              objectSub: refObjSub,
              adopt: adopt,
              ref: row,
              refObj: object,
              refObjSub: objectSub,
            });
          else
            $.each(settings.tableFields, function (i, key) {
              if (
                isPlainObject(settings.edit) &&
                getObjectValue(settings, ['edit', key, 'default'])
              )
                add[key] = calculate(
                  settings.edit[key].default,
                  settings.edit[key]
                );
              else add[key] = '';
            });
          if (settings.objectSubAddNew)
            add.objectSub = settings.objectSubAddNew;
          cont.add(add, true);
          if (settings.addMulti)
            for (var i = 1; i < settings.addMulti; i++) cont.add(add);
        }

        // new data in popup
        else {
          var sub;
          element.addClass('active');
          var pm = {
            object: refObj,
            objectSub: refObjSub,
            top: view,
            refField: cont,
            adopt: adopt,
            ref: row,
            refObj: object,
            refObjSub: objectSub,
            refKey: key,
            click: settings.addNewClick,
          };
          pm.afterSave = function () {
            if (settings.refTable && cont.saveAmount) cont.saveAmount(1);
          };
          pm.afterClose = function () {
            element.removeClass('active');
            if (param.afterClose) param.afterClose(sub);
          };
          if (settings.savePrompt) pm.savePrompt = true;
          element.addTooltip(label);
          pm.modal = true;
          sub = detail(pm);
        }
      });

    contextMenu(
      element,
      {fastSave: settings.label + ' direkt erstellen'},
      function (type) {
        if (type == 'fastSave') {
          if (refObj)
            var add = dataNew({
              object: refObj,
              objectSub: refObjSub,
              adopt: settings.adopt,
              ref: row,
              refObj: object,
              refObjSub: objectSub,
            });
          delete add.id;
          ajax({
            post: true,
            object: refObj,
            objectSub: refObjSub,
            id: 'new',
            send: add,
            callback: function (xhr) {
              if (xhr.id) cont.add(xhr);
            },
          });
        }
      }
    );
  };
  field.addCheckbutton = function () {
    element = $(
      '<button id="' +
        ident +
        '" class="btn btn-light" type="button" data-groupable="button"></button>'
    ).appendTo(cont);
    element.on(click, function () {
      if (value) element.val(0);
      else element.val(1);
      element.save();
    });
    element.val = function (val) {
      if (defined(val)) {
        value = val;
        if (val)
          element
            .empty()
            .append('<i class="icon fa-regular fa-' + settings.icon + '"></i>');
        else
          element
            .empty()
            .append('<i class="icon fa-regular fa-' + settings.icon + '"></i>');
        return element;
      } else return value;
    };
    element.val(value);
  };
  field.addPdf = function () {
    element = $(
      '<button id="' +
        ident +
        '" class="btn btn-light" type="button" data-groupable="button"><i class="icon fa-regular fa-file-pdf fa-fw"></i></button>'
    )
      .appendTo(cont)
      .addTooltip(settings.label + ' erstellen');

    var pm = {
      cont: cont,
      object: object,
      row: row,
      key: key,
      settings: settings,
    };
    element.on(click, function () {
      if (!window.tinymce)
        loadTinymce(function () {
          preparePdf(pm);
        });
      else preparePdf(pm);
    });
    contextMenu(
      element,
      {fastSave: settings.label + ' direkt erstellen'},
      function (type) {
        if (type == 'fastSave') preparePdf(Object.assign(pm, {direct: true}));
      }
    );
  };
  field.addUpload = function () {
    element = $(
      '<form class="upload btn-cont" enctype="multipart/form-data" action="" type="upload" data-type="' +
        type +
        '" data-groupable="button"></form>'
    ).appendTo(cont);
    var upload = $(
      '<input id="' +
        ident +
        '" class="upload" name="file[]" type="file" multiple="true">'
    )
      .appendTo(element)
      .on('change', function () {
        if (element.uploading) return;
        element.uploading = true;
        var action = endpoint + settings.reference;
        if (settings.refUpload) action = endpoint + settings.refUpload;
        else if (settings.uploadThis)
          action =
            endpoint +
            object +
            (param.objectSub ? '+' + param.objectSub : '') +
            ',' +
            row.id;
        if (settings.func) action += '?func=' + settings.func;
        element.attr('action', action).submit();
      });
    if (settings.accept) upload.attr('accept', settings.accept);
    var btn = $(
      '<button type="button" class="btn btn-light" data-groupable="button"><i class="icon fa-regular fa-file-arrow-up fa-fw"></i></button>'
    )
      .addTooltip(settings.label + ' hochladen')
      .prependTo(element)
      .on(click, function () {
        upload.trigger(click);
      });
    if (settings.labelBtn) btn.append(' ' + settings.labelBtn);
    var pm = {
      dataType: 'json',
      target: 'iframe',
      type: 'POST',
      data: {func: 'upload', refObj: object, refId: row.id, refKey: key},
      view: view,
      headers: {Window: windowId},
      xhrFields: {withCredentials: true},
    };
    if (debug) pm.data.debug = debug;
    pm.beforeSubmit = function (arr, el, pm) {
      loading(true);
      pm.data.refId = row.id;
      if (!settings.type && row[key]) pm.data[key] = row[key];
      element.progress = $('<span></span>').appendTo(element);
    };
    pm.success = function (xhr) {
      loading();
      var pm = {
        object: object,
        objectSub: param.objectSub,
        callback: param.callback,
        form: element,
        view: view,
        id: row.id,
      };
      delete xhr.id;
      ajaxResponse(pm, xhr);

      if (xhr.ids && !xhr.warning)
        $.each(xhr.ids, function (i, id) {
          // save ref table adopt
          if (settings.refTable && settings.adopt) {
            var pm = {
              object: settings.reference,
              objectSub: settings.objectSub,
              id: id,
              post: true,
              send: {},
              callback: function () {
                if (cont.saveAmount) cont.saveAmount(1);
              },
            };
            $.each(settings.adopt, function (key, val) {
              pm.send[key] = row[val];
            });
            ajax(pm);
          }

          // create new reference dataset and adopt document
          else if (settings.refUpload) {
            pm = {
              object: settings.reference,
              objectSub: settings.objectSub,
              post: true,
              send: {},
              callback: function (xhr) {
                cont.add(xhr.id);
              },
            };
            $.each(settings.adopt, function (key, val) {
              pm.send[key] = row[val];
            });
            pm.send[settings.refUpload] = id;
            ajax(pm);
          }

          // add to orig data set
          else if (cont.add) cont.add(data.document[id]);
        });
      if (xhr.confirm) {
        element.progress.remove();
        return;
      }
      upload.val('');
      element.attr('action', '');
      btn.removeClass('active').css('background-image', '');
      delete element.uploading;

      if (settings.updateView) view.update();
    };
    pm.uploadProgress = function (evt, position, total, complete) {
      btn.css(
        'background-image',
        'linear-gradient(to right, rgb(' +
          design.lines +
          ') ' +
          complete +
          '%, rgb(' +
          design.lines +
          ') 0)'
      );
      element.progress.text(complete + ' %');
      if (complete == 100)
        setTimeout(function () {
          element.progress.remove();
        }, 3000);
    };
    element.ajaxForm(pm);
  };
  field.addColor = function () {
    element = $('<div class="dropdown btn-cont"></div>').appendTo(cont);

    var btn = $(
      '<button id="' +
        ident +
        '" class="btn btn-light" type="button" data-mdb-toggle="dropdown" aria-expanded="false"><i class="icon fa-regular fa-fill-drip fa-fw"></i></button>'
    ).appendTo(element);
    if (settings.tipBtn) btn.addTooltip(settings.tipBtn);
    var ul = $('<ul class="dropdown-menu swatches"></ul>').appendTo(element);
    var dropdownObj = new mdb.Dropdown(btn[0]);
    $.each(
      [
        'red',
        'orange',
        'yellow',
        'green',
        'teal',
        'cyan',
        'blue',
        'indigo',
        'purple',
        'pink',
      ],
      function (i, color) {
        var li = $('<li class="d-inline-block"></li>').appendTo(ul);
        var swatch = $(
          '<span class="swatch swatch-' +
            color +
            ' d-inline-block rounded-pill m-1 link" value="' +
            color +
            '"></span>'
        )
          .appendTo(li)
          .on(click, function () {
            element.find('.active').removeClass('active');
            swatch.addClass('active');
            element.val($(this).attr('value'));
            element.changed = true;
            element.save();
            dropdownObj.hide();
          });
        if (color == value) swatch.addClass('active');
      }
    );
    $('<li><hr class="dropdown-divider"></li>').appendTo(ul);
    $(
      '<li class="d-block link"><i class="icon fa-regular fa-tint-slash fa-fw"></i> keine Farbe</li>'
    )
      .appendTo(ul)
      .on(click, function () {
        element.find('.active').removeClass('active');
        element.val('');
        element.changed = true;
        element.save();
        dropdownObj.hide();
      });
    element.val = function (val) {
      if (defined(val)) {
        value = val;
        btn.removeClassStartingWith('swatch-').addClass('swatch-' + value);
        return element;
      } else return value;
    };
    element.val(value);
  };
  field.addColorbox = function () {
    element = $(
      '<button id="' +
        ident +
        '" class="btn btn-light" type="button" data-groupable="button"></button>'
    ).appendTo(cont);
    element.val = function (val) {
      if (defined(val)) {
        value = val;
        if (isArray(val)) return;
        else if (val) {
          var color;
          if (settings.reference && value)
            color = getObjectValue(data, [settings.reference, value, 'color']);
          else if (row[key + '_color']) color = row[key + '_color'];
          if (!color) color = 'black';
          element.css({backgroundColor: color}).attr('data-id', val);
        } else element.css({backgroundColor: ''}).removeAttr('data-id');
        return element;
      } else return value;
    };
    if (settings.reference) {
      element.on(click, function () {
        if (element.attr('data-id'))
          ajax({
            func: 'detail',
            object: settings.reference,
            objectSub: settings.objectSub,
            id: element.attr('data-id'),
            modal: true,
          });
        else {
          var pm = {
            object: settings.reference,
            objectSub: settings.objectSub,
            top: view,
            refField: cont,
            adopt: settings.adopt,
            ref: row,
            refObj: object,
          };
          if (settings.objectSubAddNew) pm.objectSub = settings.objectSubAddNew;
          if (settings.savePrompt) pm.savePrompt = true;
          detail(pm);
        }
        if (param.table)
          setTimeout(function () {
            element.focus();
          }, 250); // scroll horizontal
      });
      var menu = {};
      if (settings.removeDisable) menu.disable = 'deaktivieren';
      else menu.remove = 'entfernen';
      contextMenu(element, menu, function (type) {
        if (type == 'disable')
          ajax({
            object: settings.reference,
            id: value,
            post: true,
            send: {disabled: 1},
          });
        else if (type == 'delete')
          ajax({object: settings.reference, id: value, delete: true});
        element.val(0).save();
      });
      cont.add = function (row, noSave) {
        if (row) element.val(row.id);
        if (!noSave) element.save();
      };
      element.addData = function () {
        var pm = {
          func: 'overview',
          top: view,
          object: settings.reference,
          objectSub: settings.objectSub,
          ident: settings.reference + '+' + settings.objectSub + '+picker',
          noUpdate: true,
        };
        if (settings.where)
          pm.send = {
            where: convertWhere(settings.where, {
              object: object,
              row: row,
            }),
          };
        if (settings.objectSubAdd) pm.objectSub = settings.objectSubAdd;
        pm.picker = function (ids, popup) {
          value = ids;
          element.save();
          popup.close();
        };
        ajax(pm);
      };
      element.deleteData = function () {
        ajax({
          object: settings.reference,
          objectSub: settings.objectSub,
          id: element.attr('data-id'),
          delete: true,
        });
        value = [];
        element.siblings().each(function (i, el) {
          if ($(el).attr('data-id'))
            value.push(parseInt($(el).attr('data-id')));
        });
        element.remove();
      };
    }
    if (param.table) element.addClass('tableButton');
    element.val(value);
  };
  field.addCheckbox = function () {
    element = $('<div class="form-check"></div>').appendTo(cont);
    var input = $(
      '<input id="' + ident + '" class="form-check-input" type="checkbox">'
    ).appendTo(element);
    var valueOn = 'ja';
    var valueOff = 'nein';
    if (settings.labelValue) {
      valueOn = settings.labelValue;
      valueOff = '';
    }
    if (param.injection) element.addClass('d-inline');
    else {
      element.addClass('form-switch');
      var label = $(
        '<label class="form-check-label" for="' +
          ident +
          '">' +
          valueOff +
          '</label>'
      ).appendTo(element);
    }
    element.val = function (val) {
      if (defined(val)) {
        if (isString(val)) val = parseInt(val);
        value = val;
        input.prop('checked', val);
        if (!param.injection) {
          if (val) label.text(valueOn);
          else label.text(valueOff);
        }
        changed();
      } else {
        val = input.is(':checked') ? 1 : 0;
        if (val && settings.readFilled) input.attr('disabled', true);
        return val;
      }
    };
    input.on(click, function () {
      if (!param.injection) {
        if (input.is(':checked')) label.text(valueOn);
        else label.text(valueOff);
      }
      element.changed = true;
      element.save();
    });
    if (value) element.val(value);
  };
  field.addSteps = function () {
    element = $('<div id="' + ident + '" class="field-steps"></div>').appendTo(
      cont
    );
    if (settings.values)
      $.each(settings.values, function (i, val) {
        var label = val;
        if (settings.labels && settings.labels.length == settings.values.length)
          label = settings.labels[i];
        if (!label) return;
        var btn = $(
          '<button type="button" class="btn btn-light" value="' +
            val +
            '">' +
            label +
            '</button>'
        ).appendTo(element);
        if (!settings.noClick)
          btn.on(click, function () {
            var val = $(this).val();
            if (!settings.multi)
              element.children().removeClass('success warning danger');
            if (settings.color && settings.color[val])
              $(this).toggleClass(settings.color[val]);
            else if ($(this).hasClass('success'))
              $(this).removeClass('success').addClass('warning');
            else if ($(this).hasClass('warning'))
              $(this).removeClass('warning').addClass('danger');
            else if ($(this).hasClass('danger')) $(this).removeClass('danger');
            else $(this).addClass('success');
            element.changed = true;
            element.save();
          });
        else btn.addClass('noclick');
        if (!noRemove && !settings.noClick)
          contextMenu(btn, {remove: 'entfernen'}, function (type, target) {
            if (type == 'remove') {
              target.removeClass('success warning danger');
              element.changed = true;
              element.save();
            }
          });
      });
    // setTimeout(function () {
    //   if (element.height() > 75) element.addClass('smaller1');
    //   if (element.height() > 75) element.addClass('smaller2');
    //   if (element.height() > 75) element.addClass('smaller3');
    // }, 100);

    element.val = function (val) {
      if (defined(val)) {
        value = val;
        element.children().removeClass('success warning danger');
        if (settings.multi)
          $.each(val, function (i, v) {
            if (!v) return;
            var color = 'success';
            var info;
            if (str_contains(v, ':')) [v, color, info] = v.split(':');
            else if (settings.color && settings.color[v])
              color = settings.color[v];
            var btn = element.children('[value="' + v + '"]').addClass(color);
            if (info) btn.addTooltip(info);
          });
        else if (val) {
          var color = 'success';
          var info;
          if (str_contains(val, ':')) [val, color, info] = val.split(':');
          else if (settings.color && settings.color[val])
            color = settings.color[val];
          var btn = element.children('[value="' + val + '"]').addClass(color);
          if (settings.noBack) btn.prevAll().attr('disabled', true);
          if (info) btn.addTooltip(info);
        }
        changed();
        return element;
      } else {
        val = '';
        if (settings.multi) val = [];
        element.children().each(function () {
          if (
            $(this).hasClass('success') ||
            $(this).hasClass('warning') ||
            $(this).hasClass('danger')
          ) {
            var v = $(this).attr('value');
            if (!settings.color || !settings.color[v]) {
              if ($(this).hasClass('warning')) v += ':warning';
              else if ($(this).hasClass('danger')) v += ':danger';
            }
            if (settings.multi) val.push(v);
            else {
              val = v;
              return false;
            }
          }
        });
        if (val == null) val = null;
        return val;
      }
    };
    if (isset(value)) element.val(value);
  };
  field.addRadio = function () {
    element = $('<div id="' + ident + '"></div>').appendTo(cont);
    if (!param.sidebar) element.addClass('input-group');
    var inputType = 'radio';
    if (settings.multi) inputType = 'checkbox';

    if (settings.values)
      $.each(settings.values, function (i, val) {
        var ident2 = ident + '_input' + i;
        var label = val;
        if (settings.labels && settings.labels.length == settings.values.length)
          label = settings.labels[i];
        if (!label) return;
        var cont1 = $(
          '<div class="form-check form-check-inline text-nowrap"></div>'
        ).appendTo(element);
        $(
          '<input class="form-check-input" type="' +
            inputType +
            '"  id="' +
            ident2 +
            '" name="' +
            ident +
            '" value="' +
            val +
            '">'
        )
          .appendTo(cont1)
          .on('change', function () {
            setTimeout(function () {
              element.changed = true;
              element.save();
            }, 500);
          });
        $(
          '<label class="form-check-label text-wrap" for="' +
            ident2 +
            '">' +
            label +
            '</label>'
        ).appendTo(cont1);
      });

    if (!noRemove)
      contextMenu(element, {remove: 'entfernen'}, function (type) {
        if (type == 'remove') {
          element.val(null);
          element.changed = true;
          element.save();
        }
      });

    element.val = function (val) {
      if (defined(val)) {
        value = val;
        element.find(':checked').prop('checked', false);
        if (isArray(val))
          $.each(val, function (i, v) {
            if (isWord(v))
              element.find('[value="' + v + '"]').prop('checked', true);
          });
        else if (isset(val))
          element.find('[value="' + val + '"]').prop('checked', true);
        changed();
        return element;
      } else {
        if (settings.multi)
          val = element
            .find(':checked')
            .map(function () {
              return $(this).val();
            })
            .get();
        else val = element.find(':checked').val();
        if (isNumeric(val)) val = parseInt(val);
        else if (val == null) val = null;
        return val;
      }
    };

    if (isset(value)) element.val(value);
  };
  field.addSelect = function () {
    var parentCard = $('.card-body').last();

    // append select container
    element = $('<select id="' + ident + '" class="select"></select>').appendTo(
      cont
    );
    if (!param.table) element.attr('data-mdb-clear-button', true);
    if (settings.multi) element.attr('multiple', true);

    if (settings.placeholder)
      element.attr('data-mdb-placeholder', settings.placeholder);
    if (
      settings.search ||
      (settings.values && settings.values.length > 4) ||
      (!settings.values && settings.referenceList)
    )
      element.attr('data-mdb-filter', true);
    var pm = info.select;
    pm.visibleOptions = 10;
    if (param.sidebar && getScreenWidth() < 2000) pm.displayedLabels = 1;
    if (view) {
      var ident2 = ident + '_dropwdown' + rand();
      $(
        '<div id="' + ident2 + '" class="select-dropdown-container"></div>'
      ).appendTo(view);
      pm.container = '#' + ident2;
    }

    // dynamic loop values
    if (settings.valuesFrom && settings.valuesTo) {
      var from = calculate(settings.valuesFrom, settings, row);
      var to = calculate(settings.valuesTo, settings, row);
      for (var i = from; i <= to; i++)
        $('<option value="' + i + '">' + i + '</option>').appendTo(element);
    }

    // fix values
    else if (settings.values) {
      if (
        settings.values &&
        isString(settings.values) &&
        parameter[object][settings.values]
      )
        settings.values = parameter[object][settings.values];
      if (isPlainObject(settings.values))
        $.each(settings.values, function (val, label) {
          $('<option value="' + val + '">' + label + '</option>').appendTo(
            element
          );
          if (
            param.dynamicWidth &&
            (!element.longestOption ||
              element.longestOption.length < label.length)
          )
            element.longestOption = label;
        });
      else {
        $.each(settings.values, function (i, val) {
          if (Number.isInteger(val)) settings.values[i] = val = val.toString();
          else val = val.trim();

          if (
            settings.hideValues &&
            checkWhere(row, settings.hideValues.where, {object: object}) &&
            inArray(val, settings.hideValues.values)
          )
            return;

          var label = val;
          if (
            settings.labels &&
            isPlainObject(settings.labels) &&
            settings.labels[val]
          )
            label = settings.labels[val];
          else if (
            settings.labels &&
            !isPlainObject(settings.labels) &&
            settings.labels[i]
          )
            label = settings.labels[i];
          $('<option value="' + val + '">' + label + '</option>').appendTo(
            element
          );
          if (
            param.dynamicWidth &&
            (!element.longestOption ||
              element.longestOption.length < label.length)
          )
            element.longestOption = label;
        });
      }

      // sort options
      if (settings.values.length && !settings.noSort) {
        var children = element.children();
        if (children.length) tinysort(children);
      }

      // add option
      element.addOption = function (val, label) {
        if (Number.isInteger(val)) val = val.toString();
        if (!label) label = val;
        settings.values.push(val);
        if (settings.labels) settings.labels.push(label);
        $('<option value="' + val + '">' + label + '</option>').appendTo(
          element
        );
        return element;
      };

      // remove option
      element.remOption = function (val) {
        settings.values = arrayRemove(settings.values, val);
        element.find('option[value=' + val + ']').remove();
        return element;
      };
    }

    // reference values
    else if (settings.referenceList) {
      element.ids = [];
      settings.values = {};
      var list;
      if (!settings.rowList) {
        if (isString(settings.referenceList)) list = settings.referenceList;
        else if (settings.refValue) list = object + '.' + key;
        else if (settings.objectSub)
          list = settings.reference + ucfirst(settings.objectSub);
        else list = settings.reference;
      }

      // get fresh values
      element.getList = function () {
        if (
          row.id == 'new' &&
          settings.where &&
          objectSearch(settings.where, 'id')
        )
          return;
        else if (
          settings.disabled &&
          (row.id == 'new' || checkWhere(row, settings.disabled))
        )
          return;
        // else if (line.is(':hidden')) return; -> necesseary for modal views
        else if (data.lists && data.lists[list]) return;

        var pm = {
          object: object,
          objectSub: param.objectSub,
          send: {func: 'convertRefList', key: key},
        };
        if (settings.where && row.id != 'new') {
          pm.send.refId = row.id;
          if (!settings.whereCond || checkWhere(row, settings.whereCond))
            pm.send.where = convertWhere(settings.where, {
              object: object,
              row: row,
            });
          else if (settings.where2 && checkWhere(row, settings.where2Cond))
            pm.send.where = convertWhere(settings.where2, {
              object: object,
              row: row,
            });
        }
        if (param.filter)
          pm.callback = function () {
            if (data.lists) element.valList(data.lists[list]);
          };
        if (pm.send.where === false) setData(object, row.id, key + '_list', {});
        else ajax(pm);
      };

      // update value list
      element.valList = function (val, update) {
        settings.values = val;
        if (!settings.values || isArray(settings.values)) settings.values = {};
        element.empty();

        // check values or add free value
        if (
          settings.refValue &&
          settings.refValueKey &&
          value &&
          !settings.values[value]
        )
          settings.values[value] = value;
        else if (settings.refValue && !settings.refValueKey && value) {
          if (isArray(value) && value.length)
            value.forEach(function (v) {
              if (!objectSearch(settings.values, v)) settings.values[v] = v;
            });
          else if (value && !objectSearch(settings.values, value))
            settings.values[value] = value;
        }

        // add values
        $.each(settings.values, function (id, label) {
          if (!id) return;

          var refRow;
          if (isNumber(label)) {
            id = label;
            refRow = getObjectValue(data, [settings.reference, id]);
            if (!refRow) return;
            label = refRow.short;
          } else if (!settings.refValue) {
            if (isString(id)) id = parseInt(id);
            refRow = getObjectValue(data, [settings.reference, id]);
          }
          var option = $(
            '<option value="' + id + '">' + label + '</option>'
          ).appendTo(element);
          if (refRow) {
            if (refRow.avatar) option.attr('data-mdb-icon', refRow.avatar);
            if (settings.secondaryText && refRow[settings.secondaryText])
              option.attr(
                'data-mdb-secondary-text',
                convert({
                  object: settings.reference,
                  key: settings.secondaryText,
                  value: refRow[settings.secondaryText],
                  row: refRow,
                })
              );
          }
          option.val = function (label) {
            $(this).text(label);
          };
          register(option, settings.reference, id, 'short');
          element.ids.push(id);
        });
        if (!isEmptyObject(settings.values)) {
          var children = element.children();
          if (children.length) tinysort(children);
        }
        if (settings.referenceList && !row[key + '_list'])
          row[key + '_list'] = val;

        // add empty options
        if (!settings.multi)
          element
            .prepend('<option value="" hidden></option>')
            .append('<option value="" hidden></option>');

        // set value after list change
        if (update && isset(value) && (value || row.id != 'new'))
          setTimeout(function () {
            element.val(value);
          }, 100);
      };

      if (param.list) element.valList(param.list);
      else if (row[key + '_list']) element.valList(row[key + '_list']);
      else if (
        getObjectValue(data, ['lists', list]) &&
        isPlainObject(data.lists[list])
      )
        element.valList(data.lists[list]);

      if (isEmptyObject(settings.values) && object && element.getList)
        element.getList();

      // register element
      if (settings.where) register(element, object, row.id, key + '_list');
      if (
        (!settings.where || row.id == 'new') &&
        !settings.referenceListFrontend
      )
        registerList(element, list);

      element.add = function (row) {
        $('<option value="' + row.id + '">' + row.short + '</option>').appendTo(
          element
        );
      };
      element.rem = function (row) {
        element.find('[value=' + row.id + ']').remove();
      };
    }

    // add empty options
    if (!settings.multi)
      element
        .prepend('<option value="" hidden></option>')
        .append('<option value="" hidden></option>');

    var selectObj = new mdb.Select(element[0], pm);
    selectObj._wrapper.id = ident + '_wrapper';
    element.val = function (val, retrys = 0) {
      if (defined(val)) {
        try {
          value = val;
          if (Number.isInteger(val)) val = val.toString();

          // set value(s)
          if (isArray(val)) {
            var valSelect = [];
            val.forEach(function (v) {
              if (v) valSelect.push(v.toString());
            });
            selectObj.setValue(valSelect);
          } else if (
            val === '' ||
            !isset(val) ||
            !isset(settings.values) ||
            isEmptyObject(settings.values) ||
            (isArray(settings.values) && !inArray(val, settings.values)) ||
            (isPlainObject(settings.values) && !settings.values[val])
          )
            selectObj.setValue('');
          else selectObj.setValue(val);

          // refresh value list
          if (
            settings.referenceList &&
            settings.where &&
            element.getList &&
            element.line &&
            (!settings.values ||
              isEmptyObject(settings.values) ||
              (val && isString(val) && !settings.values[val]))
          )
            element.getList();

          if (element.line) changed();
        } catch (e) {
          if (retrys <= 10)
            setTimeout(function () {
              element.val(val, retrys + 1);
            }, 1000);
        }
        return element;
      } else {
        val = $(this).val();
        if (settings.multi && !val[0]) val.shift();
        if (settings.reference && settings.multi)
          $.each(val, function (i) {
            if (isNumeric(val[i])) val[i] = parseInt(val[i]);
          });
        else if (settings.reference && isNumeric(val)) val = parseInt(val);
        else if (settings.reference && !settings.refValueKey && val === '')
          val = 0;
        if (val)
          element.children('option[value=""][selected]').removeAttr('selected'); // bug after disable field
        return val;
      }
    };
    element.val(value);

    // add value
    if (!settings.noAdd)
      cont.add = function (val, noSave) {
        if (isPlainObject(val)) val = val.id;
        if (settings.multi) {
          if (!value) value = [];
          value.push(val);
        } else value = val;
        element.val(value);
        if (!noSave)
          requestIdleCallback(function () {
            element.save();
          });
      };
    cont.rem = function (val) {
      value = arrayRemove(value, val);
      element.val(value);
      requestIdleCallback(function () {
        element.save();
      });
    };
    cont.empty = function () {
      element.val('');
      element.save();
    };

    // open select
    $(selectObj._wrapper).attr('data-groupable', 'input');
    element.on('open.mdb.select', function () {
      parentCard.css('overflow', 'visible');
      setTimeout(function () {
        $('.select-dropdown-container .select-option:visible').each(
          function (i) {
            var ident2 = ident + '_option' + i;
            if (settings.referenceList && element.ids)
              ident2 += '_' + element.ids[i];
            $(this).attr('id', ident2);
          }
        );
        if (settings.sortable)
          $('.select-dropdown-container .select-options-list').sortable();
      }, 500);
    });

    // select option
    element.on('valueChanged.mdb.select', function (evt) {
      if (!value && !evt.value) return;
      else if (isArray(value) && isArray(evt.value)) {
        if (value.length != evt.value.length) element.changed = true;
        else {
          var diff = false;
          $.each(value, function (i, v) {
            if (v != evt.value[i]) diff = true;
          });
          if (diff) element.changed = true;
        }
      } else if (value != evt.value) {
        element.changed = true;
      }
      if (element.changed) {
        changed();
        element.save();
      }
    });

    // close select
    element.close = function () {
      selectObj.close();
      parentCard.css('overflow', 'hidden');
    };

    // click search field
    if (param.focus || settings.focus)
      setTimeout(function () {
        selectObj.open();
      }, 500);
    if (param.dynamicWidth && element.longestOption)
      cont
        .find('.select-wrapper')
        .css(
          'min-width',
          $('#widthHelper').text(element.longestOption).width() + 75
        );
  };
  field.addCalendar = function () {
    var format = 'YYYY-MM-DD';
    var formatRead = 'DD.MM.YYYY';
    var changeEvent = 'valueChanged.mdb.datepicker';
    var calendarType = 'date';
    if (settings.week) calendarType = 'week';
    else if (settings.month) calendarType = 'month';
    else if (settings.time || typeOrig == 'time') calendarType = 'time';
    ident += '_' + calendarType;

    element = $(
      '<div class="form-outline p-0" data-groupable="input">'
    ).appendTo(cont);
    var input = $(
      '<input id="' + ident + '" class="form-control" type="text">'
    ).appendTo(element);

    // datetime
    if (settings.type == 'datetime') {
      cont[calendarType + 'Element'] = element;
    }

    // date
    if (calendarType == 'date') {
      element.addClass('datepicker field-date');
      input.attr('placeholder', 'tt.mm.jjjj');
      new mdb.Datepicker(
        element[0],
        Object.assign({format: 'dd.mm.yyyy', startDay: 1}, info.datepicker)
      );
    }

    // // datetime
    // else if (calendarType == 'datetime') {
    // 	element.addClass('datetimepicker');
    // 	input.attr('placeholder', 'tt.mm.jjjj --:--');
    // 	datepickerObj = new mdb.Datetimepicker(element[0], {
    // 		appendValidationInfo: false,
    // 		// showFormat: true,
    // 		defaultTime: '08:00',
    // 		datepicker: Object.assign({format: 'dd.mm.yyyy', startDay: 1}, info.datepicker),
    // 		timepicker: Object.assign({format24: true, closeModalOnMinutesClick: true, increment: true}, info.datepicker)
    // 	});
    // 	format += ' HH:mm:ss';
    // 	formatRead += ', HH:mm';
    // 	changeEvent = 'datetimeChange.mdb.datetimepicker';
    // 	saveEvent = 'close.mdb.datetimepicker';
    // }

    // week
    else if (calendarType == 'week') {
      element.addClass('datepicker field-week');
      input.attr('placeholder', 'ww');
      new mdb.Datepicker(
        element[0],
        Object.assign({format: 'yyyy-mm-dd', startDay: 1}, info.datepicker)
      );
      format = 'YYYY-\\WWWW';
      formatRead = '\\Woc\\h\\e WW, YYYY';
    }

    // time
    else if (calendarType == 'time') {
      element.addClass('timepicker field-time');
      input.attr('placeholder', '--:--');
      new mdb.Timepicker(
        element[0],
        Object.assign(
          {
            format24: true,
            closeModalOnMinutesClick: true,
            increment: true,
            appendValidationInfo: false,
          },
          info.datepicker
        )
      );
      format = 'HH:mm';
      formatRead = 'HH:mm';
      changeEvent = 'valueChanged.mdb.timepicker';
    }

    element.val = function (val, init, single) {
      if (defined(val)) {
        value = val;
        var valueRead = '';
        if (val && calendarType == 'time' && settings.type == 'datetime')
          valueRead = val.slice(11, 16);
        else if (val && calendarType == 'time') valueRead = val;
        else if (val) {
          var momentObj = convertToMoment(val, calendarType);
          valueRead = momentObj.format(formatRead);
        }
        input.val(valueRead);

        if (
          settings.type == 'datetime' &&
          cont.timeElement &&
          fields[object].allDay
        ) {
          if (row.allDay) cont.timeElement.addClass('d-none');
          else cont.timeElement.removeClass('d-none');
        }

        if (!element.line) changed();
        return element;
      } else {
        val = input.val();
        if (!val) return '';

        if (calendarType == 'week') {
          momentObj = convertToMoment(val, calendarType);
          val = momentObj.format(format);
          val = val.replace('WW', 'W');
          input.val(momentObj.format(formatRead));
        } else val = convertToMoment(val, calendarType).format(format);

        // get date and time
        if (settings.type == 'datetime' && !single) {
          if (calendarType == 'date' && cont.timeElement)
            val += ' ' + cont.timeElement.val(undefined, null, true);
          else if (calendarType == 'time' && cont.dateElement)
            val = cont.dateElement.val(undefined, null, true) + ' ' + val;
          val = convertToMoment(val, 'datetime').format('YYYY-MM-DD HH:mm');
        }

        return val;
      }
    };

    // save events
    input.on(click, function () {
      this.select();
    });
    input.on('input', function () {
      element.changed = true;
    });
    input.on('blur', function () {
      element.trigger('blur');
    });
    element.on(changeEvent, function () {
      element.changed = true;
      if (calendarType != 'date') element.save();
    });
    if (calendarType == 'date')
      element.on('close.mdb.datepicker', function (evt) {
        if (evt.namespace && element.changed) element.save();
      });

    // manipulate picker
    var manipulate = function () {
      $('.datepicker-modal-container .datepicker-day-cell').on(
        click,
        function () {
          setTimeout(function () {
            $('.datepicker-ok-btn').trigger('click');
          }, 100);
        }
      );

      if (!$('.datepicker-modal-container .datepicker-week-heading')[0])
        $(
          '<th class="datepicker-week-heading datepicker-day-heading border-end pe-2">Woche</th>'
        ).prependTo('.datepicker-modal-container .datepicker-table thead tr');
      $('.datepicker-modal-container .datepicker-table-body tr').each(
        function (i, tr) {
          var firstTd = $(this).children('td:not(.disabled)').first();
          if (!firstTd[0]) return;
          let [year, month, day] = firstTd.attr('data-mdb-date').split('-');
          var date = year + '-' + (parseInt(month) + 1) + '-' + day;
          var week = moment(date).format('WW');
          $(
            '<td class="datepicker-small-cell-content text-center border-end pe-2 link">' +
              week +
              '</td>'
          )
            .prependTo(tr)
            .on(click, function () {
              $(tr).children('.datepicker-day-cell').trigger('click');
            });
          if (calendarType == 'week')
            $(tr).on(click, function () {
              $(this).addClass('bg-light');
            });
        }
      );
      $('.datepicker-modal-container').width(360);
    };
    element.on('open.mdb.datepicker', function () {
      setTimeout(function () {
        manipulate();

        // arrow click
        $('.datepicker-modal-container .datepicker-arrow-controls button').on(
          click,
          manipulate
        );

        // year click
        $('.datepicker-modal-container .datepicker-view-change-button').on(
          click,
          function () {
            setTimeout(function () {
              $('.datepicker-modal-container .datepicker-year-cell').on(
                click,
                function () {
                  setTimeout(function () {
                    $('.datepicker-modal-container .datepicker-month-cell').on(
                      click,
                      function () {
                        setTimeout(function () {
                          manipulate();
                        }, 100);
                      }
                    );
                  }, 100);
                }
              );
            }, 100);
          }
        );
      }, 100);
    });

    // context menu
    contextMenu(element, {datepicker: info.datepicker.title}, function (type) {
      if (type == 'datepicker') element.children('button').trigger('click');
    });

    if (value) element.val(value, true);
    if (read) input.attr('readonly', true);
  };
  field.addEditor = function () {
    element = $('<textarea class="form-control"></textarea>').appendTo(cont);
    var editor = {};
    var pm = {
      target: element[0],
      skin: false,
      content_css: window.fileUrls.wysiwyg.toString(),
      body_class: 'wysiwyg',
      language: 'de',
      plugins:
        'autoresize lists advlist autolink link image searchreplace visualblocks visualchars charmap media code table pagebreak insertdatetime editimage', // powerpaste
      menubar: 'edit view insert format tools table',
      toolbar:
        'reload preview vars pattern | bold italic | forecolor backcolor | styleselect | fontsizeselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | undo redo',
      toolbar_mode: 'sliding',
      fontsize_formats:
        '8px 10px 11px 12px 13px 14px 15px 16px 18px 24px 36px 48px',
      table_sizing_mode: 'responsive',
      table_use_colgroups: false,
      images_upload_url:
        endpoint + 'document?func=upload&fromEditor=1&window=' + windowId,
      file_picker_types: 'image',
      automatic_uploads: false,
      images_reuse_filename: true,
      pagebreak_separator: '<page></page>',
      pagebreak_split_block: true,
      allow_conditional_comments: true,
      insertdatetime_formats: [
        '<br>----- %d.%m.%Y, %H:%M (' + user.short + ') -----<br><br>',
      ],
      advlist_bullet_styles: 'default,circle,disc,square',
      advlist_number_styles:
        'default,lower-alpha,lower-greek,lower-roman,upper-alpha,upper-roman',
    };
    if (key != 'preview') pm.toolbar = 'insertdatetime ' + pm.toolbar;
    if (!develop) pm.deprecation_warnings = false;
    if (window.pdfCss) pm.content_style = window.pdfCss;
    if (settings.width) pm.width = settings.width;
    if (key == 'preview') param.minimal = true;
    if (param.minimal) pm.statusbar = false;
    if (
      param.focus ||
      (optionValue({settings: settings, objectSub: objectSub, key: 'focus'}) &&
        !view.focusDone)
    ) {
      pm.auto_focus = true;
      view.focusDone = true;
    }
    pm.max_height = ($(window).height() / 4) * 3;
    pm.setup = function (editor) {
      if (key == 'preview')
        editor.ui.registry.addButton('reload', {
          icon: 'reload',
          tooltip: 'Aktualisieren',
          onAction: function () {
            element.val('');
            element.save();
            element.changed = false;
            element.view.update();
          },
        });
      if (object == 'pdfPattern' || param.pdfPreview)
        editor.ui.registry.addButton('preview', {
          icon: 'preview',
          tooltip: 'PDF-Vorschau',
          onAction: function () {
            var send = {func: 'preview'};
            if (object == 'pdfPattern') {
              send.file = row.id;
              send.refObj = row.object;
            } else {
              send.html = element.val();
              if (isString(param.file)) send.file = param.file;
              else if (view.lines.file)
                send.file = view.lines.file.element.val();
            }
            ajax({
              object: 'document',
              post: true,
              view: view,
              send: send,
              callback: function (xhr) {
                if (xhr.doc) buildFilePopup({id: xhr.doc});
              },
            });
          },
        });
      if (!param.minimal)
        editor.ui.registry.addButton('vars', {
          icon: 'code-sample',
          tooltip: info.tooltip.addVar,
          onAction: function () {
            var selectboxes = [];

            var mainSelect = {
              field: 'select',
              values: [],
              labels: [],
              sort: true,
            };
            mainSelect.label = getObjectValue(parameter, [row.object, 'label']);
            if (!mainSelect.label) mainSelect.label = info.title.mainFields;
            selectboxes.push(mainSelect);

            var vars = getObjectValue(fields, [row.object]);
            if (!vars) vars = fields.default;
            $.each(vars, function (key, settings) {
              if (
                settings.label &&
                !inArray(key, ['id', 'warning', 'disabled'])
              ) {
                mainSelect.values.push(key);
                mainSelect.labels.push(settings.label + ' (' + key + ')');
              }

              if (
                settings.reference &&
                !inArray(settings.reference, ['document', 'mailStat']) &&
                settings.type != 'text'
              ) {
                var select = {
                  label: settings.label + ' (falls ausgefüllt)',
                  field: 'select',
                  values: [],
                  labels: [],
                  sort: true,
                };
                $.each(fields[settings.reference], function (key2, settings) {
                  if (
                    settings.label &&
                    !inArray(key, ['id', 'warning', 'disabled'])
                  ) {
                    select.values.push(key + '.' + key2);
                    select.labels.push(settings.label + ' (' + key2 + ')');
                  }
                });
                selectboxes.push(select);
              }
            });

            var popup = buildPopup({
              title: info.tooltip.addVar,
              bodyCard: true,
            });
            $.each(selectboxes, function (i, pm) {
              pm.view = popup;
              pm.save = function (val) {
                var label = getObjectValue(fields, [row.object, val, 'label']);
                if (str_contains(val, '.')) {
                  const [refKey, refKey2] = val.split('.');
                  var refObj = getObjectValue(fields, [
                    row.object,
                    refKey,
                    'reference',
                  ]);
                  label = getObjectValue(fields, [row.object, refKey, 'label']);
                  label +=
                    ' ' + getObjectValue(fields, [refObj, refKey2, 'label']);
                }
                if (label)
                  editor.insertContent(
                    '<input class="var" type="button" data-key="' +
                      val +
                      '" value="' +
                      label +
                      '">'
                  );
                popup.close();
              };
              buildFormLine(popup.body, pm);
            });
          },
        });
      editor.ui.registry.addButton('pattern', {
        icon: 'template',
        tooltip: info.tooltip.addPatternBlock,
        onAction: function () {
          ajax({
            object: 'pattern',
            callback: function (xhr) {
              var select = {
                label: 'Textbausteine',
                key: 'pattern',
                object: 'pattern',
                field: 'select',
                reference: 'pattern',
                values: xhr.ids,
                labels: [],
              };
              $.each(xhr.ids, function (i, id) {
                select.labels.push(data.pattern[id].title);
              });
              buildPromptPopup([select], function (value) {
                var html = getObjectValue(data, ['pattern', value, 'text']);
                if (!html) return;
                editor.insertContent(html);
                element.changed = true;
              });
            },
          });
        },
      });
      editor.on('wheel', function (evt) {
        if (editor.pageY == evt.pageY) view.body[0].scrollTop += evt.deltaY;
        editor.pageY = evt.pageY;
      });
    };
    tinymce.init(pm).then(function (editors) {
      editor = element.editor = editors[0];
      if (value) element.val(value, true);
      editor.on('keyup ExecCommand ObjectResized', function (evt) {
        if (inArray(evt.command, ['mceUpdateImage', 'mceInsertContent']))
          setTimeout(function () {
            $(editor.contentDocument.body)
              .find('img:not([width])')
              .each(function () {
                if ($(this).width() > 1000) $(this).attr({width: 1000});
              });
            if (object) element.save();
          }, 250);
        else if (evt.command != 'mceFocus') element.changed = true;
        $(document).one(click, function () {
          if (element.changed && object) element.save(false, false, true);
        });
      });
      editor.on('mouseleave', function () {
        if (element.changed && object) element.save(false, false, true);
      });
    });
    element.val = function (val, first) {
      if (defined(val) && (!editor || !editor.setContent)) {
        value = val;
        $(this).val(val);
      } else if (defined(val)) {
        value = val;
        if (val == null) val = '';
        // replace vars
        else if (!param.minimal)
          val = val.replace(/__([a-z0-9.]+)__/gi, function (match, variable) {
            if (val.search(new RegExp('style="[^"]*' + match + '[^"]*"')) > -1)
              return match;
            var label = getObjectValue(fields, [
              row.object,
              variable,
              'reference',
            ]);

            // reference field
            if (str_contains(variable, '.')) {
              const [refKey, refKey2, refKey3] = variable.split('.');

              if (refKey == 'pattern') {
                label = getObjectValue(parameter, [object, 'label']);
                label +=
                  ' ' + getObjectValue(fields, [object, refKey2, 'label']);
              } else if (refKey == 'store' || refKey == 'user') {
                label = getObjectValue(parameter, [refKey, 'label']);
                label +=
                  ' ' + getObjectValue(fields, [refKey, refKey2, 'label']);
              } else {
                label = getObjectValue(fields, [row.object, refKey, 'label']);
                var refObj = getObjectValue(fields, [
                  row.object,
                  refKey,
                  'reference',
                ]);
                label +=
                  ' ' + getObjectValue(fields, [refObj, refKey2, 'label']);
                if (refKey3) {
                  var refObj2 = getObjectValue(fields, [
                    row.object,
                    refKey2,
                    'reference',
                  ]);
                  label +=
                    ' ' + getObjectValue(fields, [refObj2, refKey3, 'label']);
                }
              }
            }

            if (label)
              return (
                '<input class="var" type="button" value="' +
                label +
                '" data-key="' +
                variable +
                '">'
              );
            else return match;
          });

        editor.setContent(val);
        editor.getWin().scrollTo(0, 0);
        if (first) editor.undoManager.clear();

        // clean
        $(editor.contentDocument.body)
          .find('table')
          .each(function () {
            if ($(this).parent('div')[0]) $(this).unwrap();
          });
      } else {
        val = editor.getContent();

        // correct td vars
        if (!param.minimal && str_contains(val, '__td')) {
          $(editor.contentDocument.body)
            .find('table')
            .each(function () {
              var amount = $(this).find('tr:first td').length;
              $(this)
                .find('td[style]')
                .attr('style', function (index, style) {
                  return style.replace(/__td[0-9]__/, '__td' + amount + '__');
                })
                .removeAttr('data-mce-style');
            });
          val = editor.getContent();
        }

        // replace vars
        if (!param.minimal)
          val = val.replace(
            /<input class="var" type="button" value="[^"]+" data-key="([^"]+)">/g,
            function (match, keep) {
              return '__' + keep + '__';
            }
          );

        return val;
      }
    };
  };
  field.addTextarea = function () {
    element = $(
      '<textarea id="' +
        ident +
        '" class="form-control" data-groupable="input"></textarea>'
    ).appendTo(cont);
    element.val = function (val) {
      if (settings.saveAdditional && val) {
        $(this).val('');
        element.old = '';
      } else if (defined(val)) {
        value = val;
        if (val == null) val = '';
        else if (isArray(val)) val = val.join();
        else if (isPlainObject(val)) val = Object.values(val).join();
        val = val.replace(/&euro;/g, '€');
        $(this).val(val);
        element.old = val;
        setTimeout(function () {
          if (element.is(':visible')) element.setHeight();
        }, 250);
        changed();
        return element;
      } else {
        val = $(this).val();
        return val;
      }
    };
    if (settings.placeholder) element.attr('placeholder', settings.placeholder);
    // if (settings.saveAdditional) element.on('keydown',function(evt){
    // 	if (evt.key != 'Enter') return;
    // 	if (evt.ctrlKey){
    // 		var pos = this.selectionStart;
    // 		this.value = this.value.slice(0,pos)+"\n"+this.value.slice(pos);
    // 		this.selectionEnd = pos+1;
    // 	}
    // 	else if (element.changed){ element.save(); element.val(''); }
    // 	return false;
    // });
    // else
    if (settings.saveAdditional) {
      $(
        '<button class="btn btn-light send" data-groupable="input"><i class="icon fa-regular fa-paper-plane fa-fw"></i></button>'
      )
        .on(click, function () {
          element.save();
          element.val('');
        })
        .insertAfter(element)
        .addTooltip('Abschicken [CTRL+Enter]');
      element.on('save', function () {
        element.save();
        element.val('');
      });
    } else
      element.on('save', function () {
        if (element.changed) element.save();
      });
    element.on('keyup', function () {
      if (element.old != $(this).val()) {
        element.changed = true;
        element.old = $(this).val();
        element.setHeight();
      }
    });
    element.setHeight = function () {
      $(this).height(
        $('#heightHelper').width($(this).width()).val($(this).val())[0]
          .scrollHeight - 5
      );
    };
    element.on('show', function () {
      element.setHeight();
    });
    if (value) element.val(value);
  };
  field.addStream = function () {
    element = $('<div id="' + ident + '" class="stream mb-3"></div>').appendTo(
      cont
    );
    element.val = function (val) {
      if (defined(val)) {
        value = val;
        element.empty();
        $.each(val, function (i, v) {
          var streamCont = $('<div class="d-flex"></div>').appendTo(element);
          $.each(settings.streamFields, function (j, k) {
            var pm = {value: v[k], view: view};
            pm.fieldIdent = ident + '_' + j + '_' + k;
            var el = buildField(streamCont, pm, k, settings.fields[k]);
            if (k == 'message' && v.user == user.id)
              contextMenu(el, {delete: 'Beitrag löschen'}, function (type) {
                if (type == 'delete') {
                  value.splice(i, 1);
                  streamCont.remove();
                  element.save();
                }
              });
          });
        });
        return element;
      } else {
        val = $(this).val();
        return val;
      }
    };
    if (value) element.val(value);
  };
  field.addSign = function () {
    element = $('<canvas id="' + ident + '" height="200"></canvas>').appendTo(
      cont
    );
    setTimeout(function () {
      element.attr('width', cont.width() - 0);
    }, 250);
    element.val = function (val) {
      if (defined(val)) {
        value = val;
        if (val)
          cont
            .find('canvas,.button')
            .removeClass('d-inline-block')
            .addClass('d-none');
        else if (element.build)
          cont
            .find('canvas,.button')
            .removeClass('d-none')
            .addClass('d-inline-block');
        else signBuild();
        return element;
      } else return value;
    };
    element.val(value);

    var clickX = [];
    var clickY = [];
    var clickDrag = [];
    var paint;
    var context = element[0].getContext('2d');
    function signBuild() {
      element.removeClass('d-none').addClass('d-inline-block');

      // Mausereignisse
      element.mousedown(function (evt) {
        paint = true;
        signClick(evt.offsetX, evt.offsetY);
        signRedraw();
      });
      element.mousemove(function (evt) {
        evt.preventDefault();
        if (!paint) return;
        signClick(evt.offsetX, evt.offsetY, true);
        signRedraw();
      });
      element.on('mouseup mouseleave', function (evt) {
        evt.preventDefault();
        paint = false;
      });

      // Touch-Ereignisse
      element.on('touchstart', function (evt) {
        evt.preventDefault();
        var touch = evt.originalEvent.touches[0];
        var mouseX = touch.pageX - $(this).offset().left;
        var mouseY = touch.pageY - $(this).offset().top;
        paint = true;
        signClick(mouseX, mouseY);
        signRedraw();
      });

      element.on('touchmove', function (evt) {
        evt.preventDefault();
        if (!paint) return;
        var touch = evt.originalEvent.touches[0];
        var mouseX = touch.pageX - $(this).offset().left;
        var mouseY = touch.pageY - $(this).offset().top;
        signClick(mouseX, mouseY, true);
        signRedraw();
      });

      element.on('touchend touchleave touchcancel', function (evt) {
        evt.preventDefault();
        paint = false;
      });
      $(
        '<button class="btn btn-light" type="button"><i class="icon fa-regular fa-eraser fa-fw"></i></button>'
      )
        .insertAfter(element)
        .on(click, function () {
          context.clearRect(0, 0, context.canvas.width, context.canvas.height);
          clickX = [];
          clickY = [];
          clickDrag = [];
        });
      $(
        '<button class="btn btn-light" type="button"><i class="icon fa-regular fa-save fa-fw"></i></button>'
      )
        .insertAfter(element)
        .on(click, function () {
          var img = element[0].toDataURL('image/png');
          var fileName = settings.label + '.png';
          ajax({
            object: 'document',
            post: true,
            send: {file: img, fileName: fileName},
            callback: function (xhr) {
              element.val(xhr.id);
              element.save();
            },
          });
        });
      element.build = true;
    }
    function signClick(x, y, dragging) {
      clickX.push(x);
      clickY.push(y);
      clickDrag.push(dragging);
    }
    function signRedraw() {
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      context.lineWidth = 3;
      for (var i = 0; i < clickX.length; i++) {
        context.beginPath();
        if (clickDrag[i] && i) context.moveTo(clickX[i - 1], clickY[i - 1]);
        else context.moveTo(clickX[i] - 1, clickY[i]);
        context.lineTo(clickX[i], clickY[i]);
        context.closePath();
        context.stroke();
      }
    }
  };
  field.addProgress = function () {
    element = $('<div id="' + ident + '" class="progress">').appendTo(cont);
    var bar = $(
      '<div class="progress-bar progress-bar-animated bg-success" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>'
    ).appendTo(element);
    element.val = function (val) {
      if (defined(val)) {
        value = val;
        var text = val + settings.entity;
        if (!val) {
          val = 0;
          text = '';
        }
        bar.attr('aria-valuenow', val).css('width', text).text(text);
        return element;
      } else {
        val = parseInt($(this).attr('aria-valuenow'));
        return val;
      }
    };
    element.val(value);
  };
  field.addInput = function () {
    if (type == 'password') settings.password = true;
    else if (type == 'search') settings.search = true;
    else if (type == 'colorpicker') type = 'color';
    else if (type == 'email') {
      settings.validate =
        '^[a-zäöüß0-9._%+-]+@[a-zäöüß0-9.-]{2,}\\.[a-z]{2,10}$';
      settings.validateTip = info.validate.mail;
      settings.lower = true;
    }
    var placeholder = '';
    if (settings.placeholder) placeholder = settings.placeholder;
    else if (settings.decimal || type == 'decimal') placeholder = '0,00';
    else if (settings.number || type == 'number') placeholder = 0;
    element = $(
      '<input id="' +
        ident +
        '" class="form-control" type="' +
        type +
        '" data-groupable="input" data-1p-ignore>'
    ).appendTo(cont);
    if (placeholder) element.attr('placeholder', placeholder);
    if (settings.password) element.attr('autocomplete', 'new-password');
    else element.attr('autocomplete', 0);
    if (settings.number) {
      element.attr({step: 'any', lang: 'de-DE'});
      if (settings.type == 'number') element.attr('min', 0);
    }
    if (settings.maxlength) element.attr('maxlength', settings.maxlength);
    if (settings.step) element.attr('step', settings.step);

    // autocomplete
    if (settings.values || row[key + '_values']) {
      var values = settings.values;
      if (row[key + '_values']) values = row[key + '_values'];
      var contInput = element.parent().addClass('form-group');
      const dataFilter = (value) => {
        return values.filter((item) => {
          return item.toLowerCase().startsWith(value.toLowerCase());
        });
      };
      setTimeout(function () {
        new mdb.Autocomplete(contInput[0], {filter: dataFilter, noResults: ''});
        if (line && line.label) line.label.removeClass('autocomplete-label');
      }, 100);
      contInput.on('itemSelect.mdb.autocomplete', function () {
        setTimeout(function () {
          element.save();
        }, 500);
      });
    }

    element.val = function (val) {
      if (defined(val)) {
        value = val;
        if (settings.password && (settings.hash || settings.md5)) return;
        if (settings.reference && settings.refKey && !isNumber(value))
          $(this).val(val);
        else
          $(this).val(
            convert({
              value: val,
              settings: settings,
              input: true,
              row: row,
              key: key,
              object: object,
              index: next,
            })
          );
        changed();
        if (settings.percent && element.entity && element.entity.val() == '%')
          setTimeout(function () {
            element.calculatePercent();
          }, 100);
        return element;
      } else {
        val = $(this).val();
        if (val) val = convertBack(val, settings);
        if (settings.percent && element.entity && element.entity.val() == '%')
          val = (row[settings.percent] * val) / 100;
        return val;
      }
    };
    element.on('input', function () {
      element.changed = true;
    });
    if (settings.search) {
      cont
        .attr('class', 'search')
        .attr('role', 'search')
        .prepend(
          '<i class="icon fa-regular fa-search fa-fw" aria-hidden="true"></i>'
        );
      element.on(click, function () {
        setTimeout(function () {
          var val = element.val();
          if (!val && value) {
            element.changed = true;
            element.save();
          }
        }, 250);
      });
    }

    cont.add = function (row, noSave) {
      if (settings.multiple && line.addLine && value) return line.addLine(row);

      var val = value;
      if (settings.refKey) val = row[settings.refKey];
      else if (settings.reference && settings.array) val.push(row.id);
      else if (settings.reference) val = row.id;
      else val = row[key];
      element.val(val);
      if (!noSave && object) element.save();
    };

    if (settings.percent) {
      element.calculatePercent = function () {
        var val = row[key];
        if (element.entity.val() == '%') val = row[key + '_percent'];
        $(this).val(
          convert({
            value: val,
            settings: settings,
            input: true,
            row: row,
            key: key,
            object: object,
          })
        );
      };
      if (settings.percentShow)
        setTimeout(function () {
          if (line.els[0]) {
            line.els[0].entity.val('%');
            element.calculatePercent();
          }
        }, 1000);
    }

    element.val(value);
    type = 'input';
  };

  // build field
  if (this['add' + ucfirst(type)]) field['add' + ucfirst(type)]();
  else field.addInput();

  // save events
  if (inArray(type, ['input', 'textarea', 'calendar'])) {
    if (!settings.search && !settings.saveAdditional && !param.noMouseout)
      element.on('blur mouseout', function () {
        if (!element.changed || element.block) return;
        element.save();
        element.block = true;
        setTimeout(function () {
          delete element.block;
        }, 100);
      });
    element.on('keydown', function (evt = {}) {
      if (evt.keyCode == 13) {
        if (type == 'textarea' && !evt.ctrlKey) return;
        element.save();
        if (param.enter) param.enter();
        else
          setTimeout(function () {
            if (cont.focusNext) cont.focusNext();
          }, 100);
      }
    });
  }

  // general
  if (read) element.attr('readonly', true);
  if (view) element.view = view;
  if (row.id) register(element, object, row.id, key); // register field
  if (param.click && param.click == key && (value || type == 'add')) {
    element.trigger(click);
    delete param.click;
  }
  if (settings.highlight && element.is('button'))
    element.toggleClass('btn-light btn-primary');
  if (isset(settings.min))
    element.attr(
      'min',
      convertWhereValue(key, settings.min, {}, {object: object})
    );
  if (isset(settings.max))
    element.attr(
      'max',
      convertWhereValue(key, settings.max, {}, {object: object})
    );
  if (param.classField) element.addClass(param.classField);
  if (next && settings['classField' + next])
    element.addClass(settings['classField' + next]);
  else if (!next && settings.classField) element.addClass(settings.classField);
  else if (settings.class) cont.addClass(settings.class);
  if (cont.els) cont.els.push(element);
  if (
    optionValue({settings: settings, objectSub: objectSub, key: 'focus'}) &&
    element.length
  )
    setTimeout(function () {
      element[0].focus();
    }, 1000);

  // context menu
  if (settings.edit && settings.field != 'table')
    contextMenu(element, {edit: info.context.changeValue}, function (type) {
      if (type == 'edit') {
        var val = prompt(info.context.newValue, value);
        if (val === null) return;
        else if (val === '') val = null;
        if (settings.number && val && isNaN(val)) {
          showInfo(info.error.numberRunning, 'error');
          return;
        }
        if (val != value) element.val(val).save();
      }
    });
  if (settings.multiple) {
    line.addLine = function (val = '') {
      if (isPlainObject(val) && settings.refKey) val = val[settings.refKey];
      if (isArray(val)) val = val[0];

      var last = line.find('li:last');
      var lastInput = last.find('input');
      if (lastInput.val()) {
        var li = last.clone(true).insertAfter(last);
        li.find('input').each(function (i, el) {
          el = $(el).val(val);
          if (settings.fields) el.key = settings.fields[i];
          line.els.push(el);
        });
      } else lastInput.val(val);

      value = val;
      changed();
    };
    var context = function (type, target, label, evt) {
      if (type == 'addLine') line.addLine();
      else if (type == 'removeLine' && line.els.length > 1) {
        $(evt.currentTarget).parents('li:first').remove();
        element.save();
      }
    };
    contextMenu(
      element,
      {
        addLine: info.tooltip.addRow.replace('__name__', settings.label),
        removeLine: info.tooltip.removeRow.replace('__name__', settings.label),
      },
      context
    );
    if (cont.is('td')) contextMenu(cont, {add: info.context.addRow}, context);
    // element.on('enter', function () {
    // 	line.addLine();
    // });
  }

  // add new data
  if (settings.reference)
    element.addNew = function (adopt = {}) {
      var sub;
      var pm = {
        object: settings.reference,
        objectSub: settings.objectSub,
        top: view,
        refField: cont,
        adopt: Object.assign(settings.adopt, adopt),
        ref: row,
        refObj: object,
        modal: true,
        afterClose: function () {
          element.removeClass('active');
          if (param.afterClose) param.afterClose(sub);
        },
      };
      if (settings.objectSubAddNew) pm.objectSub = settings.objectSubAddNew;
      if (settings.savePrompt) pm.savePrompt = true;
      sub = detail(pm);

      if (param.table)
        setTimeout(function () {
          tableScroll(element);
        }, 250);
    };

  if (!param.table) {
    context = {};
    if (
      !inArray(type, ['button', 'add', 'addNew', 'upload', 'table', 'stream'])
    )
      context.copy = info.context.copyValue;
    if (
      !inArray(type, [
        'button',
        'add',
        'addNew',
        'upload',
        'read',
        'table',
        'stream',
      ]) &&
      !read
    )
      context.paste = info.context.pasteValue;
    if (!inArray(type, ['button', 'add', 'addNew', 'upload', 'stream']))
      context.history = info.context.history;
    if (!isEmptyObject(context))
      contextMenu(element, context, function (type, target, label, evt) {
        if (type == 'copy') {
          var value = element.val();
          if (!value) value = element.text();
          navigator.clipboard.writeText(
            convert({value: value, settings: settings, exact: true})
          );
          showInfo(info.success.successCopy, 'success');
        } else if (type == 'paste')
          navigator.clipboard.readText().then(function (text) {
            element.val(text).save();
          });
        else if (type == 'history') {
          var tip = getFieldHistory(row.history, key);
          if (tip) {
            window.showTooltip(tip, evt);
            element.addTooltip(tip);
          }
        }
      });
  }

  // scanner input
  if (settings.scan)
    contextMenu(element, {scan: 'Scan'}, function (type) {
      if (type == 'scan')
        buildScan(function (code) {
          element.val(code).save();
        });
    });

  // individual settings
  // if (settings.values && !inArray(type, ['checkbox', 'radio']) && inArray(role, ['superadmin', 'admin', 'boss']))
  // 	contextMenu(element, {values: 'Standardwerte eintragen'}, function (type) {
  // 		if (type == 'values') {
  // 			var val = '';
  // 			if (settings.values) val = settings.values.join(',');
  // 			buildPromptPopup([{label: 'Werte', value: val, field: 'textarea'}], function (val) {
  // 				ajax({
  // 					post: true,
  // 					object: 'setting',
  // 					send: {
  // 						object: object,
  // 						key: key,
  // 						option: 'values',
  // 						all: 1,
  // 						value: val
  // 					}
  // 				});
  // 				if (strpos(val, '|') > 0) val = val.split('|');
  // 				else val = val.split(',');
  // 				fields[object][key].values = val;
  // 				if (datalist) datalist.empty();
  // 				else {
  // 					var ident = rand();
  // 					element.attr('list', ident);
  // 					var datalist = $('<datalist id="' + ident + '"></datalist>').appendTo(cont);
  // 				}
  // 				$.each(val, function (i, v) {
  // 					$('<option value="' + v + '">').appendTo(datalist);
  // 				});
  // 			});
  // 		}
  // 	});
  if (settings.pdfValue)
    contextMenu(
      element,
      {pdfValue: 'Textbaustein bearbeiten'},
      function (type) {
        if (type == 'pdfValue') {
          buildPromptPopup(
            [{value: settings.pdfValue, field: 'editor'}],
            function (val) {
              ajax({
                post: true,
                object: 'setting',
                send: {
                  object: object,
                  key: key,
                  option: 'pdfValue',
                  all: 1,
                  value: val,
                },
              });
              settings.pdfValue = val;
            }
          );
        }
      }
    );

  // save
  element.save = function (callback, send, noDataController) {
    if (param.noSave || settings.noSave) return;
    if (!settings.fields && settings.tableFields)
      settings.fields = settings.tableFields;

    // stream data
    if (settings.json && settings.fields && settings.saveAdditional) {
      value = data[object][row.id][key];
      if (!value) value = [];
      var val = element.val();
      if (val) {
        var valueRow = {};
        $.each(settings.fields, function (key, set) {
          if (key == settings.saveAdditional) valueRow[key] = val;
          else if (set.default) valueRow[key] = calculate(set.default);
        });
        if (!isEmptyObject(valueRow)) {
          var previous = value[value.length - 1];

          // add to previous if same user and under 5 minutes
          if (
            value.length &&
            previous.user == valueRow.user &&
            previous.time &&
            getMinutesSince(previous.time) < 5
          ) {
            valueRow[settings.saveAdditional] =
              previous[settings.saveAdditional] +
              '\n' +
              valueRow[settings.saveAdditional];
            value[value.length - 1] = valueRow;
          } else {
            value.push(valueRow);
            element.saved = true;
          }
        }
      }
    }

    // multiple json field
    else if (settings.json && settings.multiple) {
      var valueNew = [];
      valueRow = {};
      var last = settings.fields.at(-1);
      $(line.els).each(function (i, el) {
        if (!el || !el.is('input') || el.is(':hidden')) return;
        var val = el.val();
        if (val) valueRow[el.key] = val;
        if (el.key == last) {
          if (!isEmptyObject(valueRow)) valueNew.push(valueRow);
          valueRow = {};
        }
      });
      data[object][row.id][key] = valueNew;
      value = valueNew;
    }

    // multiple data
    else if (settings.multiple && !settings.reference) {
      value = [];
      $(line.els).each(function (i, el) {
        if (el && el.is(':visible')) {
          var val = el.val();
          if (val) value.push(val);
        }
      });
    }

    // normal save
    else value = element.val();

    // special function
    if (settings.save && isFunction(settings.save)) {
      settings.save(value, element, key, cont.els, cont);
      changed(true);
      return;
    } else if (param.save) {
      param.save(value, element, key, cont.els, cont);
      changed(true);
      return;
    }
    if (!key) return;

    // parameter
    var pm = {
      post: true,
      object: object,
      objectSub: param.objectSub,
      id: row.id,
      view: view,
    };

    // validate
    if (settings.validate && value && isString(value)) {
      if (settings.lower) value = value.toLowerCase();
      if (cont.warning) {
        cont.warning.remove();
        delete cont.warning;
      }
      if (!value.match(settings.validate)) {
        cont.warning = $(
          '<span class="text-danger"><i class="icon fa-regular fa-exclamation-triangle me-1"></i>' +
            settings.validateTip +
            '</span>'
        ).appendTo(cont);
        return;
      }
    }

    // pre
    showInfo(info.save);
    if (noDataController) pm.noDataController = true;
    if (row.id) setData(object, row.id, key, value, element);
    if (!send) send = {};
    if (!isset(send[key])) send[key] = value;

    // reference field
    if (!row.id && param.refField && param.adopt)
      row = dataNew($.extend(true, {}, param, {new: send}));

    // save also other new fields
    if (row.id == 'new' && !row.saving)
      $.each(data[object][row.id], function (key2, val) {
        if (isset(send[key2])) return;
        else if (getObjectValue(param, ['adopt', key2])) send[key2] = val;
        else if (
          key2 == 'id' ||
          str_starts_with(key2, 'short') ||
          str_contains(key2, '_')
        )
          return;
        else if (typeof val == 'object' && isEmptyObject(val)) return;
        else if (isset(val)) send[key2] = val;
      });
    else if (param.bulk) {
      send.func = 'bulk';
      param.table.filtered(send);
    }

    if (param.beforeSave) param.beforeSave(send);
    if (isArray(send[key]) && !send[key].length) send[key] = '';
    if (settings.editInfo) send.editInfo = prompt(settings.editInfo);
    if (settings.waiting) pm.waiting = true;
    pm.send = send;

    // after save
    pm.callback = function (xhr) {
      if (xhr.id) {
        param.id = row.id = xhr.id;
        if (param.refField) {
          if (param.refField.line)
            param.refField.line.add(data[object][row.id]);
          else param.refField.add(data[object][row.id]);
        }
      }
      if (!xhr.warning && !xhr.info)
        showInfo(info.save + ' <i class="fa fa-check"></i>');
      if (callback) callback();
      if (param.afterSave) param.afterSave(xhr.id);
      if (
        settings.save &&
        getObjectValue(fields, [object, settings.save, 'field']) == 'read' &&
        getObjectValue(elements, [object, row.id, settings.save + '_els'])
      )
        $.each(
          elements[object][row.id][settings.save + '_els'],
          function (i, el) {
            if (el) el.val(row[settings.save]);
          }
        );
      // if (getObjectValue(elements, [object, row.id, key + '_connected']))
      // 	$.each(elements[object][row.id][key + '_connected'], function (i, el) {
      // 		if (el && $.contains(document.body, el[0])) el.line.update();
      // 		delete elements[object][row.id][key + '_connected'][i];
      // 	});
      if (settings.afterSave && window[settings.afterSave])
        window[settings.afterSave](element, param);
      if (view && view.afterFirstSave) {
        $.each(view.afterFirstSave, function (i, callback) {
          callback();
        });
        view.afterFirstSave = [];
      }
      if (settings.updateField)
        setData(
          object,
          row.id,
          settings.updateField,
          row[settings.updateField],
          null,
          objectSub,
          null,
          true
        );
      if (settings.updateView) view.update();

      // update view
      // if (
      // 	parameter[object].checkObjectSub &&
      // 	row[parameter[object].checkObjectSub] != getObjectValue(param, ['subs', 'value']) &&
      // 	(!objectSub || !parameter[object]['short' + ucfirst(objectSub)] || !parameter[object]['form' + ucfirst(objectSub)])
      // ) {
      // 	checkObjectSub(param);
      // 	if (objectSub != param.objectSub) view.update();
      // }
      if (
        settings.view &&
        isset(settings.view[value]) &&
        settings.view[value] != objectSub
      ) {
        param.objectSub = settings.view[value];
        view.update();
      }

      changed(true);
    };

    // first save not finished yet
    if (row.id == 'new' && row.saving) {
      var retry = setInterval(function () {
        if (row.id != 'new') {
          pm.id = row.id;
          ajax(pm);
          delete row.saving;
          clearInterval(retry);
        }
      }, 1000);
      return;
    }

    // save
    ajax(pm);
    if (row.id == 'new') row.saving = true;
  };

  // adopt is missing
  // if (!value && param.adopt && param.ref && param.ref[param.adopt[key]] && settings.reference && settings.number && row.id != 'new') element.val(param.ref[param.adopt[key]]).save();
  // if (row.id != 'new' && param.adopt && param.adopt[key] && param.ref && param.ref[param.adopt[key]]){
  // 	if (!row[key]) element.val(param.ref[param.adopt[key]]).save();
  // 	else if (isArray(row[key]) && inArray(param.ref[param.adopt[key]],row[key]) == -1){
  // 		if (param.adopt[key] == 'id') cont.add(param.ref);
  // 		else if (settings.reference) cont.add(data[settings.reference][param.ref[param.adopt[key]]]);
  // 	}
  // }

  // value changed
  function changed(selfTriggered) {
    if (value) cont.addClass('filled');
    else cont.removeClass('filled');

    var empty = isEmpty(value);
    if (settings.mandatory && !read && view) {
      if (settings.mandatory && !view.mandatory) view.mandatory = [];
      if (empty && !inArray(key, view.mandatory)) {
        line.find('.form-control').addClass('is-invalid');
        view.mandatory.push(key);
      } else if (!empty && inArray(key, view.mandatory)) {
        line
          .find('.form-control')
          .removeClass('is-invalid')
          .next('.invalid-feedback')
          .remove();
        view.mandatory.splice(view.mandatory.indexOf(key), 1);
      } else if (!empty && element.hasClass('is-invalid'))
        line
          .find('.form-control')
          .removeClass('is-invalid')
          .next('.invalid-feedback')
          .remove();
    }

    if (view && view.formBuilt && !param.bulk)
      hideElements(
        Object.assign({}, param, {
          row: row,
          key: key,
          line: cont,
          value: value,
          selfTriggered: selfTriggered,
        })
      );
    if (selfTriggered && element.changed) {
      delete element.changed;
      if (param.afterChanged) param.afterChanged();
    }
    if (view && view.formBuilt && settings.updateFieldLines)
      $.each(settings.updateFieldLines, function (i, key) {
        if (view.lines[key]) view.lines[key].update();
      });

    // adjust width
    if (param.dynamicWidth && type == 'select')
      setTimeout(function () {
        var width = 'auto';
        var str = cont.find('.select-input').val();
        if (str) width = $('#widthHelper').text(str).width() + 75;
        cont.find('.select-wrapper').width(width);
      }, 50);
  }

  element.line = line;
  return element;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function getFieldHistory(history, key) {
  var str = [];
  $.each(history, function (i, row) {
    if (row.key == key) {
      var strAdd = [];
      strAdd.push(convert({value: row.stamp, settings: {date: true}}));
      if (row.userVal) {
        var editUser = getData('user', row.userVal);
        if (!editUser.admin) strAdd.push(editUser.short);
        else if (user.admin) strAdd.push(editUser.username);
      }
      str.push(row.new + ' (' + strAdd.join(' ') + ')');
    }
  });
  return str.join('<br>');
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function preparePdf(param = {}) {
  var fileNameEl, fileEl, editorEl;
  var object = param.object;
  var objectSub = param.objectSub;
  var key = param.key;
  var cont = param.cont;
  var settings = param.settings ? param.settings : {};
  var row = param.row ? param.row : {};
  var rowPopup = {};

  var pmCreate = {
    object: 'document',
    view: view,
    post: true,
    send: {func: 'pdf', refObj: object, refId: row.id, refKey: key, new: {}},
    waiting: true,
    buttons: {},
  };
  if (checkSettings('mail', settings))
    pmCreate.buttons[settings.label + ' verschicken'] = function () {
      mailer({
        settings: settings,
        object: object,
        row: row,
        key: key,
        close: 'prev',
      });
    };
  if (param.createExit) {
    var result = param.createExit(pmCreate);
    if (result === false) return;
  }
  if (row[key] && !settings.multi && !settings.refTable) pmCreate.id = row[key];
  if (settings.orientation) pmCreate.send.orientation = settings.orientation;
  pmCreate.callback = function (xhr) {
    if (xhr.id) cont.add(data.document[xhr.id]);
    if (xhr.doc && !pmCreate.noFileview)
      buildFilePopup({
        id: xhr.doc,
        buttons: param.buttons,
        sign: settings.sign,
        close: param.close,
      });
  };

  var file = optionValue({
    settings: settings,
    key: 'pdfPattern',
    objectSub: param.objectSub,
  });
  if (!file && checkSettings('select', settings))
    $.each(cont.els, function (i, el) {
      if (el.is('select')) file = el.val();
    });

  // adopt
  if (settings.adopt)
    $.each(settings.adopt, function (key, val) {
      pmCreate.send.new[key] = row[val];
    });

  // html from preview field
  if (file && getObjectValue(fields, [object, file, 'field']) == 'editor') {
    // pmCreate.send.html = Object.values(elements[object][row.id][file+'_els'])[0].val();
    $.each(view.lines, function (i, line) {
      if (line.key == file) {
        pmCreate.send.html = line.element.val();
        return false;
      }
    });
    if (
      param.objectSub &&
      fields[object][file]['pdfPattern' + ucfirst(param.objectSub)]
    )
      pmCreate.send.file =
        fields[object][file]['pdfPattern' + ucfirst(param.objectSub)];
    else if (fields[object][file].pdfPattern)
      pmCreate.send.file = fields[object][file].pdfPattern;
    else if (fields[object][file].file) {
      pmCreate.send.file = fields[object][file].file;
      if (fields[object][pmCreate.send.file])
        pmCreate.send.file = row[pmCreate.send.file];
    }
    ajax(pmCreate);
  }

  // direct creation
  else if ((param.direct || settings.noPreview) && file) {
    if (!param.downloader) pmCreate.send.file = file;
    ajax(pmCreate);
  }

  // html in next popup
  else {
    // if (xhr.file){ buildFilePopup({src:xhr.file,top:dialog}); return; }
    // if (!xhr.value)	xhr.value = '';
    var popup;
    var pmPopup = {
      title: 'PDF ' + settings.label + ' erstellen',
      buttons: {},
    };

    // create pdf after html popup
    var pdf = function (blank) {
      pmCreate.send.html = editorEl.val();
      pmCreate.send.fileName = fileNameEl.val();
      if (fileEl) pmCreate.send.file = fileEl.val();
      else pmCreate.send.file = file;
      if (blank) pmCreate.send.blank = true;
      if (param.createExit) {
        result = param.createExit(pmCreate);
        if (result === false) return;
      }
      ajax(pmCreate);
    };
    pmPopup.buttons['PDF erstellen'] = function () {
      popup.close();
      pdf(rowPopup.backgroundSkip);
    };

    popup = buildPopup(pmPopup);
    var pm = {
      view: popup,
      settings: $.extend(true, {}, dialogForms.pdfPreview),
      row: rowPopup,
      pdfPreview: true,
      file: file,
      noTimeout: true,
    };

    // pattern fields
    // if (!patternFields && fields[object][file]) patternFields = settings['patternFields' + ucfirst(row[file])];
    if (
      settings.patternFields &&
      (!settings.patternFieldsCond ||
        checkWhere(row, settings.patternFieldsCond))
    ) {
      var patternFields = settings.patternFields;
      var keys = [];
      if (isArray(patternFields)) keys = patternFields;
      else if (patternFields.keys) keys = patternFields.keys;
      else keys = Object.keys(patternFields);
      $.each(keys, function (i, key2) {
        var settings2 = patternFields[key2];
        if (!settings2) settings2 = fields.pdfPattern.patternFields[key2];
        if (!isPlainObject(settings2)) return;
        else if (settings2.hidden && inArray(row[file], settings2.hidden))
          return;

        if (settings2.patternKey)
          settings2 = fields.pdfPattern.patternFields[settings2.patternKey];
        pm.settings[key2] = settings2;
        var value = localStorage.getItem('patternValue_' + key + '_' + key2);
        if (!isset(value) && settings2.default)
          value = calculate(settings2.default, settings2, row, {
            object: object,
          });
        if (value && isString(value) && settings2.multi)
          value = value.split(',');
        if (!value || (isArray(value) && !value.length)) value = '';
        pm.settings[key2].value = rowPopup[key2] = value;
        pm.settings.form.push(':' + key2 + '>');

        if (settings2.calculate)
          param.createExit = function () {
            if (rowPopup[key2] && rowPopup[key2] !== '0')
              ajax({
                object: object,
                id: row.id,
                post: true,
                send: settings2.calculate,
              });
          };
      });
      pm.save = function (value, element, key2) {
        localStorage.setItem('patternValue_' + key + '_' + key2, value);
        rowPopup[key2] = value;
        if (!value || (isArray(value) && !value.length)) rowPopup[key2] = '';
        if (key2 == 'backgroundSkip' || patternFields[key2].calculate) return;

        ajax({
          object: 'pdfPattern',
          id: file,
          send: {
            refObj: object,
            refId: row.id,
            full: true,
            patternValues: rowPopup,
            preparePdf: true,
          },
          callback: function () {
            var pdfPattern = data.pdfPattern[file];
            fileNameEl.val(pdfPattern.name);
            editorEl.val(pdfPattern.text);
          },
        });
      };
    }

    // additional fields
    pm.settings.backgroundSkip = {
      field: 'checkbox',
      label: 'Ohne Briefbogen',
    };
    pm.row.backgroundSkip = getLocalFlag(
      'patternValue_' + key + '_backgroundSkip'
    );
    pm.settings.form.push(':backgroundSkip>');

    // save preview text
    if (settings.saveCache) {
      pm.object = object;
      pm.settings.preview.save = function (value) {
        var pm2 = {object: object, id: row.id, post: true, send: {}};
        pm2.send[settings.saveCache] = row[settings.saveCache] = value;
        ajax(pm2);
      };
    }

    // build form
    buildForm(popup.body, pm, pm.settings.form);
    fileNameEl = popup.lines.fileName.element;
    editorEl = popup.lines.preview.element;

    // stored preview html
    // if (row[key + 'Preview']){
    // 	ajax({
    // 		object: object,
    // 		objectSub: objectSub,
    // 		id: row.id,
    // 		top: view,
    // 		send: {func: 'pattern', html: file, key: key}
    // 	});
    // 	editor.element.val(row[key + 'Preview']);
    // }

    // single pdf pattern
    if (file && !isArray(file)) {
      if (fields[object][file] && file != key) {
        if (
          fields[object][file].values &&
          isArray(fields[object][file].pdfPattern)
        )
          file =
            fields[object][file].pdfPattern[
              fields[object][file].values.indexOf(row[file])
            ];
        else file = row[file];
      }
      if (settings.pdfPatternAdd)
        $.each(settings.pdfPatternAdd, function (add, cond) {
          if (checkWhere(row, cond)) file += ucfirst(add);
        });
      pm = {
        object: object,
        objectSub: objectSub,
        id: row.id,
        top: view,
        send: {func: 'pattern', file: file, key: key},
      };
      pm.callback = function (xhr) {
        if (!data.pdfPattern) return;
        var pattern = data.pdfPattern[xhr.pattern];
        if (xhr.html) editorEl.val(xhr.html);
        if (xhr.descr) pmCreate.send.descr = xhr.descr;
        if (pattern) {
          if (pattern.name)
            fileNameEl.val(
              convertPattern({text: pattern.name, row: row, object: object})
            );
          pmCreate.send.fileName = fileNameEl.val();
          file = pattern.id;
        }
        if (xhr.fromCache) {
          var infoLi = $(
            '<p class="text-danger">' + info.warning.pdfCache + ' </p>'
          ).insertBefore(editorEl);
          $('<span class="link">' + info.prompt.update + '</span>')
            .appendTo(infoLi)
            .on(click, function () {
              editorEl.val('');
              editorEl.save();
              editorEl.changed = false;
              popup.update();
            });
        }
      };
      if (!isEmptyObject(rowPopup)) pm.send.patternValues = rowPopup;
      ajax(pm);
    }

    // multi pdf pattern
    else {
      pm = {
        object: 'pdfPattern',
        send: {
          preparePdf: true,
          where: {type: 'pdf'},
          refObj: object,
          refId: row.id,
          full: true,
        },
      };
      if (file && isArray(file) && isNumeric(file[0])) pm.send.where.id = file;
      else if (file) pm.send.where.ident = file;
      else pm.send.where.object = object;
      if (!isEmptyObject(rowPopup)) pm.send.patternValues = rowPopup;
      pm.callback = function (xhr) {
        if (!xhr.ids || !xhr.ids.length) return;
        var right = popup.body.find('[data-site=right]').eq(0);
        var li = addPatternField(right, {
          object: 'pdfPattern',
          ref: object,
          label: 'PDF-Vorlage',
          patterns: xhr.ids,
          view: popup,
          callback: function (row) {
            fileNameEl.val(
              convertPattern({text: row.name, row: row, object: object})
            );
            editorEl.val(row.text);
          },
        });
        fileEl = li.element;
        popup.lines.file = li;

        // default
        var patternId = xhr.ids[0];
        var row = data.pdfPattern[patternId];
        fileEl.val(patternId);
        fileNameEl.val(
          convertPattern({text: row.name, row: row, object: object})
        );
        editorEl.val(row.text);
      };
      ajax(pm);
    }

    popup.update = function () {
      popup.close();
      preparePdf(param);
    };
  }
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function tableScroll(element) {
  var left =
    element.parents('td').position().left -
    element.parents('.table').width() +
    50;
  element.parents('.table').scrollLeft(left);
}
